import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { constantStrings, statusCodeString } from '../shared/helpers/constants';
import { AddSurgeonModal, INITIAL_ADDSURGEON_MODAL_STATE, INITIAL_MODAL_STATE, Modal } from '../shared/models/dialog.model';
import { AuthService } from '../shared/services/auth.service';
import { SharedStateService } from '../shared/services/shared-state.service';
import { ImageUploadComponent } from '../uploadfiles/image-upload/image-upload.component';
import { UploadService } from '../uploadfiles/upload.service';
import { UploadSuccessComponent } from '../uploadsuccess/uploadsuccess.component';
import { LoggedUserDetails, settingsResponse } from 'src/app/shared/models/user.model';
import * as dicomParser from "dicom-parser";
import { environment } from 'src/environments/environment';
import { BnNgIdleService } from 'bn-ng-idle';
import * as JSZip from "jszip";
import * as stream from 'stream';
import * as AWS from 'aws-sdk';
//import { saveAs } from 'file-saver';
import * as S3 from 'aws-sdk/clients/s3';
import * as Lambda from 'aws-sdk/clients/lambda';
import { errorMessages } from '../shared/helpers/errorMessages';
import { Buffer } from 'buffer';
import * as CryptoJS from 'crypto-js';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-uploadinprogress',
  templateUrl: './uploadinprogress.component.html',
  styleUrls: ['./uploadinprogress.component.scss'],
})
export class UploadInProgressComponent implements OnInit, OnDestroy {

  @Output() isErrorFlag: EventEmitter<number> = new EventEmitter<number>();
  @Input() modal: Modal = INITIAL_MODAL_STATE;
  public addModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;
  @ViewChild(ImageUploadComponent) FacilityModel;
  public showUploadProgressModal: boolean;
  isProgressBarVisible: boolean;
  isZipProgressBarVisible: boolean;
  zipPercentDone: number = 0;
  zipProgress : boolean = true;
  displaySelectionFacility:string;
  displaySelectionSurgeon:string;
  displaySelectionApplication:string;
  isUploadInProgress = true;
  recallDetails: string;
  errRecallDetails: string;
  errUserDetails: string;
  statusReq: FormData;
  fileSizeUnit: number = 1024;
  loadedFile:string;
  totalFile:string;
  uploadSpeed:number;
  remainingTime = 0;
  TimeinSeconds = 0;
  timeInMinutes= 0;
  @ViewChild(UploadSuccessComponent) SuccessModel;
  fileStatus: string;
  timeFlagCounter = 1;
  uploadFileCount:number = 1;
  isError;
  percentDone: number=0;
  uploadSuccess: boolean;
  progressPercentage: string;
  zipProgressPercentage: string;
  time_start:number;
  end_time:number;
  timeDuration:number;
  id: string;
  skippedFileCount: number;
  ValidDicomCount: number;
  percentResultLoaded:number;
  percentCount: number;
  uploadFileDetailObj: any;
  localStorageUploadFileDetailObj: any;
  percentloaded: number;
  updatedBacthSize: number;
  surgeonRes: any;
  auditAction: any;
  files= [];
  fileType:number;
  allFiles = [];
  dicomTagsObj = [];
  skippedFiles = [];
  uploadDate : Date = new Date();
  errorMessage: string;
  skippedFileRes: any;
  counter: number = 0;
  readerCount: number;
  filesLength: number;
  dicomTagSSM = [];
  skipMandTagsFlag : boolean = false;
  skipInValidDicomFlag : boolean = false;
  sqsQueueUrl: string;
  receiveValue($event) {
    this.showUploadProgressModal = $event;
    this.isProgressBarVisible = $event;
  }
  unsubscriptionFileAPI: Subscription;
  facilityStateDataUnsubscription: Subscription;
  applicationStateDataUnsubscription: Subscription;
  surgeonStateDataUnsubscription:Subscription;
  getUploadStatusAPIUnsubscription:Subscription;
  batchId:string;
  errorDetails: any;
  displayFacility: string;
  displaySurgeon: string;
  displayApplication: string;
  batchSize:number=0;
  loadedBatchSize:number = 0;
  resultLoaded: number = 0;
  timeInHours:number;
  minutes:number;
  flattenedApiId: string;
  actionActivityDetails: settingsResponse;
  userInfo: LoggedUserDetails;
  errOutcome: number = 0;
  successOutcome: number = 1;
  eventOutcome: number;
  auditText: string | number;
  auditId: string | number;
  validDicomFlag: boolean = true;
  uploadId : string;
  key : string;
  abortStatus : any;
  loadedSize : any = 0;
  isPathTooLongError: boolean = false;
  preValueOfuloadFiles : any = [];
  constructor(
    private translateService: TranslateService,
    public router: Router,
    private uploadService: UploadService,
    private http: HttpClient,
    private spinnerService: NgxSpinnerService,
    private sharedState: SharedStateService,
    private authService: AuthService,
    private actRouter: ActivatedRoute,
    private bnIdle: BnNgIdleService,
    private changeDetector: ChangeDetectorRef) {
    this.showUploadProgressModal = true;
    this.isProgressBarVisible = true;
    this.isZipProgressBarVisible = true;
    this.actRouter.queryParams
      .subscribe(params => {
        this.id = params.batchId;
      });
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });

    //Recieving display selection value from state service
    this.facilityStateDataUnsubscription = this.sharedState.facilityStateData$.subscribe(response => {
      this.displayFacility = response.displaySelectionfacility;
    })

    this.surgeonStateDataUnsubscription = this.sharedState.surgeonObjectStateData$.subscribe(response => {       //get surgeon object stored in state service
      this.surgeonRes = response.surgeon;
      this.displaySurgeon = this.surgeonRes.firstname + ' ' + this.surgeonRes.lastname;
    })

    this.applicationStateDataUnsubscription = this.sharedState.applicationStateData$.subscribe(response => {
      this.displayApplication = response.displaySelectionApplication;
    })
    //Recieving loggedIn user details from local storage
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
    }

    let uploadFileStorage = this.authService.getItem('uploadFileStatus');
    if (uploadFileStorage) {
      for (let statusFile of uploadFileStorage) {
        this.batchSize = statusFile.batchSize;
      }
    }
  }

  ngOnInit(): void {
    //Getting Sqs queue url from SSM parameter
    this.authService.getSSMValue(environment.ssmParams.SQSQUEUEURL);
    this.sharedState.ssmIdleTimeData$.subscribe(response => {
      if(response && response.ssmKey == environment.ssmParams.SQSQUEUEURL){
        this.sqsQueueUrl = response.ssmVal;
      }
    });
    this.dicomTagSSM = this.uploadService.dicomTagSSM;
    let uploadFileStorage = this.authService.getItem('uploadFileStatus');
    if (uploadFileStorage) {
      this.localStorageUploadFileDetailObj = uploadFileStorage;
      for (let statusFile of uploadFileStorage) {
        if (this.id) {
          if (statusFile.batchId == this.id) {
            this.displaySelectionApplication = statusFile.applicationName;
            this.displaySelectionFacility = statusFile.facilityName;
            this.displaySelectionSurgeon = statusFile.surgeonName
            if(!(statusFile.facilityName)){
              this.router.navigate(['/uploadfiles'])
            }
          }
        } else {
          this.displaySelectionApplication = this.displayApplication;
          this.displaySelectionFacility = this.displayFacility;
          this.displaySelectionSurgeon = this.displaySurgeon;
          if(!(this.displayFacility)){
            this.router.navigate(['/uploadfiles'])
          }
        }

      }
    }

    if(!this.id){
      this.processFile();
    }
    //When we click on any uploaded record from Upload status screen to check the status
    if(this.id != '' && this.id){
    this.zipSubjectCall();
    this.sharedState.uploadProgressData$.subscribe(response => {
        let uploadFilesStorage = this.authService.getItem('uploadFileStatus');
        if(uploadFilesStorage){
          for(let statusFile of uploadFilesStorage){
            if(statusFile.batchId == this.id){
              if(statusFile.fileStatus == constantStrings.PROCESSING) {
                this.showUploadProgressModal = true;
                this.isProgressBarVisible = true;
                this.percentDone  = statusFile.percentDone;
                this.loadedFile = statusFile.loadedFile;
                this.totalFile = statusFile.totalFile;
                this.TimeinSeconds = statusFile.TimeinSeconds;
                this.timeInMinutes = statusFile.timeInMinutes;
                this.timeInHours = statusFile.timeInHours;
                this.progressPercentage = statusFile.progressPercentage;
              }
              if(statusFile.fileStatus == constantStrings.UPLOADED && this.showUploadProgressModal && this.isProgressBarVisible) {
                let notification = {
                  dicomFolder: this.allFiles,
                  validDicom: this.dicomTagsObj,
                  invalidDicom: this.skippedFiles,
                  batchId: statusFile.batchId,
                  totalFileCount:this.filesLength,
                  uploadDate : this.uploadDate
                };
                this.sharedState.setDicomStateData(notification);
                this.id = '';
                this.showUploadProgressModal = false; //Hide Upload progress page content on call of Success modal
                this.isProgressBarVisible = false;
                this.addModalUi = {       //Success modal defined
                  display: 'block',
                  header: 'Upload File Success',
                  content: '',
                  okButton: 'Save',
                  cancelButton: 'Cancel',
                  submitFunction: () => { },
                  closeFunction: () => {
                  }
                };
              }
            }
          }
        }
      });
    }
  }

  zipSubjectCall(){
    this.sharedState.zipProgressData$.subscribe(response => {
      this.preValueOfuloadFiles =  response;
      for(let zipstatusFile of this.preValueOfuloadFiles){
        if(zipstatusFile.batchId == this.id){
            this.showUploadProgressModal = true;
            this.zipProgressPercentage = zipstatusFile.zipProgressPercentage;
            this.isZipProgressBarVisible = zipstatusFile.isZipProgressBarVisible;
            this.zipProgress = zipstatusFile.zipProgress;
            this.zipPercentDone = zipstatusFile.zipPercentDone;
        }
      }
      this.changeDetector.detectChanges();
    });
  }
  /**
 * (description):  process S3 upload zip file
 **/
  private S3(bucket): any {
    this.setCognitoConfigObject();
    const clientParams: any = {
        region: environment.region,
        params: { Bucket: bucket }
    };
    if (environment.s3_endpoint) {
        clientParams.endpoint = environment.s3_endpoint;
    }
    const s3 = new S3(clientParams);

    return s3;
}

   public progressBar(progress, size, batchId) {
    let uploadFileStorage = this.authService.getItem('uploadFileStatus');
    this.loadedSize = this.loadedSize + progress.total;
    this.percentDone  = Math.round((this.loadedSize / size) * 100);
    this.loadedFile = this.formatFileSize(this.loadedSize);
    this.totalFile = this.formatFileSize(size);
    const currentTime = new Date().getTime();
    if(!this.time_start){
      this.time_start = currentTime;
    }
    const elapsedTime = currentTime - this.time_start;
    const totalTime = (elapsedTime / this.loadedSize) * size;
    const remainingTime = totalTime - elapsedTime;
    this.TimeinSeconds = Math.round((remainingTime / 1000) % 60);
    this.timeInMinutes = Math.round((remainingTime / (1000 * 60)) % 60);
    this.timeInHours = Math.round((remainingTime / (1000 * 60 * 60)) % 24);
    this.progressPercentage = `${this.percentDone}%`;

    for(let statusFile of uploadFileStorage){
      if(statusFile.batchId == batchId && statusFile.fileStatus != constantStrings.CANCELLED){
          statusFile.fileStatus = constantStrings.PROCESSING;
          statusFile.percentDone = this.percentDone;
          statusFile.loadedFile = this.loadedFile;
          statusFile.totalFile = this.totalFile;
          statusFile.TimeinSeconds = this.TimeinSeconds;
          statusFile.timeInMinutes = this.timeInMinutes;
          statusFile.timeInHours = this.timeInHours;
          statusFile.progressPercentage = this.progressPercentage;
      }
    }
    this.authService.setItem('uploadFileStatus', uploadFileStorage);
    this.sharedState.uploadProgressPer(this.percentDone);
  }

  public uploadObject(selectedFile, batchId) {
    this.progressPercentage = '0%';
    this.zipProgress = false;
    this.isProgressBarVisible = true;
    const _self = this;
    this.key = batchId+"/"+selectedFile[0].name;
    let obj = {
      fileName: selectedFile[0].name,
      batchSize: selectedFile[0].size,
    }
    this.dicomTagsObj.push(obj);
    if(this.dicomTagsObj.length !=0){
        this.time_start = new Date().getTime();
        this.uploadService.startTime = this.time_start;
    }
    this.multipartUpload(this.key, environment.duuUploadBucket, selectedFile[0], batchId).then(response => {
      _self.callLambdaFunction(batchId + "/" + selectedFile[0].name, batchId);
    }).catch(err => {
      if(!this.sharedState.abortStatus){
        this.uploadFileDetailObj = this.authService.getItem('uploadFileStatus');
        if(this.uploadFileDetailObj.length > 0){
          for(let i = 0;i < this.uploadFileDetailObj.length;i++){
              if(this.uploadFileDetailObj[i].batchId == this.batchId || this.uploadFileDetailObj[i].batchId == this.id){
              this.uploadFileDetailObj[i].fileStatus= constantStrings.FAILED;
              }
          }
          this.authService.setItem('uploadFileStatus', this.uploadFileDetailObj);
        }
        _self.auditLogActivity(constantStrings.FAILED, this.errOutcome);
        _self.uploadFailed(batchId);
       }
       else{
        this.sharedState.abortStatus=undefined;
       }
    });
}

  /**
  * (description): function called for Multiparting zip file and checking SHA256 integrity check
  * (parameter) : Key, Bucket, File
  * (memberof) : Component
  */
async multipartUpload(key: string, bucket: string, file: File, batchId: any):Promise<any>{
    var s3 = new AWS.S3();
    const _self = this;
    this.uploadId = await this.createMultipartUpload(key, bucket,s3);

    //adding uploadid in session
    let uploadFileDetailObj = this.authService.getItem('uploadFileStatus');
    uploadFileDetailObj.filter(x=>x.batchId===batchId).map(c=>{
      c['uploadId']=this.uploadId;
      c['key']=key;
    });
    this.authService.setItem('uploadFileStatus', uploadFileDetailObj);
    
    let partSize = 8 * 1024 * 1024;
    const numParts = Math.ceil(file.size / partSize);
    const partPromises = [];

    for (let partNumber = 0; partNumber < numParts; partNumber++){
      const start = partNumber * partSize;
        const end = Math.min(start + partSize, file.size);
        const filePart = file.slice(start, end);
        const sha256Value = await _self.calculateSHA256(filePart);
        const checksum = Buffer.from(sha256Value, 'hex').toString('base64');
        partPromises.push(this.uploadPart(filePart, partNumber + 1, this.uploadId, checksum, bucket, key, s3, file.size, batchId));
      }
      const uploadedParts = await Promise.all(partPromises);
    return await this.completeMultipartUpload(this.uploadId, uploadedParts, bucket, key, s3);
  }

  async createMultipartUpload(key: string, bucket: string, s3: AWS.S3): Promise<string> {
    const params = {
      Bucket: bucket,
      Key: key,
      ChecksumAlgorithm  : 'SHA256'
    };
    return new Promise((resolve, reject) => {
      s3.createMultipartUpload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          this.uploadFileDetailObj = this.authService.getItem('uploadFileStatus');
          if(this.uploadFileDetailObj.length > 0){
            for(let i = 0;i < this.uploadFileDetailObj.length;i++){
                if(this.uploadFileDetailObj[i].batchId == this.batchId || this.uploadFileDetailObj[i].batchId == this.id){
                  if(this.uploadFileDetailObj[i].fileStatus == constantStrings.CANCELLED && data.UploadId){
                    this.sharedState.abortStatus = this.abortMultipartUpload(data.UploadId, environment.duuUploadBucket, s3, this.key);
                   } else {
                    resolve(data.UploadId);
                  }
                }
            }
          }
        }
      });
    });
  }

  async uploadPart(filePart: Blob, partNumber: number, uploadId: string, checksum: string, bucket: string, key: string, s3:AWS.S3, size : any, batchId: any): Promise<any> {
    const _self = this;
    const params = {
      Bucket: bucket,
      Key: key,
      PartNumber: partNumber,
      UploadId: uploadId,
      Body: filePart,
      ChecksumAlgorithm: 'SHA256',
      ChecksumSHA256: checksum
    };
    return new Promise((resolve, reject) => {
      s3.uploadPart(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve({
            ETag: data.ETag,
            PartNumber: partNumber,
            ChecksumSHA256: checksum
          });
        }
      }).on('httpUploadProgress', function(progress:any) {
        if((progress.loaded == progress.total) && (_self.loadedSize !== size) && (_self.loadedSize < size)){
          _self.progressBar(progress, size, batchId)
        }
      });
    });
  }

  private completeMultipartUpload(uploadId: string, parts: any[], bucket: string, key: string,s3: AWS.S3): Promise<any> {
    const params = {
      Bucket: bucket,
      Key: key,
      UploadId: uploadId,
      MultipartUpload: {
        Parts: parts.map(part =>({
          ETag: part.ETag,
          PartNumber: part.PartNumber,
          ChecksumSHA256: part.ChecksumSHA256
        })),
      }
    };
    return new Promise((resolve, reject) => {
      s3.completeMultipartUpload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  private abortMultipartUpload(uploadId: string, bucket: string, s3: AWS.S3, key: string,): Promise<any> {
    const params = {
      Bucket: bucket,
      Key: key,
      UploadId: uploadId
    };

    return new Promise((resolve, reject) => {
      s3.abortMultipartUpload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  private formatFileSize(bytes: any) {
    let precision: number = 2;
    if (bytes === 0 || bytes === '0' ) {
      return '0KB';
    } else if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
      return '-';
    } else {
        let k = 1024,
        // tslint:disable-next-line:no-magic-numbers
        dm = precision || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
 private calculateSHA256(filePart: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const wordArray = CryptoJS.lib.WordArray.create(reader.result as ArrayBuffer);
        const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex);
        resolve(hash);
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(filePart);
    });
  }


public setCognitoConfigObject () {
  let userPoolConfig: string = environment.userPoolAwsLogin;
  let loginObj: any = {};
  loginObj[userPoolConfig] = this.authService.getCognitoIdTokenFromStorage();
  let awsCredentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: environment.identityPoolId,
      Logins: loginObj
  });
  awsCredentials.clearCachedId();
   awsCredentials.refresh((err) => {
       // Refresh the credentials
   })
  AWS.config.credentials = awsCredentials;
  AWS.config.region = environment.region;
}

zipProgressBar(){
  let zipFileStorage = this.preValueOfuloadFiles;
  for(let zipstatusFile of zipFileStorage){
    if(zipstatusFile.batchId == this.batchId){
        zipstatusFile.zipProgressPercentage = this.zipProgressPercentage;
        zipstatusFile.isZipProgressBarVisible = this.isZipProgressBarVisible;
        zipstatusFile.zipProgress = this.zipProgress;
        zipstatusFile.zipPercentDone = this.zipPercentDone;
    }
  }
  this.changeDetector.detectChanges();
  this.sharedState.zipProgressPer(zipFileStorage);
}

processFile(){
  this.isPathTooLongError = false;
  this.files = this.uploadService.file;
  this.batchId = this.uploadService.batchid;
  this.fileType = this.uploadService.fileType;
  if(this.id == '' || !this.id){
    this.id = this.uploadService.batchid;
  }
  if(this.files){
    this.filesLength = this.files.length;
    if(this.fileType===2){
      let i = 1;
      const interval = setInterval(() => {
        if (i <= 100) {
          this.zipProgressPercentage = `${i}%`;
          this.zipPercentDone = i;
          this.zipProgress = true;
          this.zipProgressBar();
          i++;
        } else {
          clearInterval(interval); // Stop the interval when progress reaches 100%
          if(this.zipProgressPercentage == '100%'){
            this.percentDone = 1;
            this.loadedFile = this.formatFileSize(0);
            this.totalFile = this.formatFileSize(0);
            setTimeout(() => {
              this.isZipProgressBarVisible = false;
              this.zipProgress = false;
              this.zipProgressBar();
              setTimeout(() => {
                this.uploadObject(this.files, this.batchId);
              }, 100);
            }, 400);
          }
        }
      }, 50);
    }
    else {
      var _self = this;
      let new_name = this.files[0].webkitRelativePath.split('/')[0];
      new_name = new_name+'.zip';
      var zip = new JSZip();
        var fileslist=this.files;
        var files = zip.folder(new_name);
        for(let i=0; i<fileslist.length; i++){
          // const fileWithDirName = fileslist[i].webkitRelativePath.split('/').join('_');
          files.file(fileslist[i].webkitRelativePath, fileslist[i]);
        }

        const onProgress = (progress) => {
            this.zipProgressPercentage = progress.percent.toFixed(2) + "%";
            this.zipPercentDone = +progress.percent.toFixed(2);
            this.zipProgress = true;
            if(this.zipProgressPercentage == '100.00%'){
              this.percentDone = 1;
              this.loadedFile = this.formatFileSize(0);
              this.totalFile = this.formatFileSize(0);
              this.isZipProgressBarVisible = false;
              this.zipProgress = false;
            }
            this.zipProgressBar();
          }

          zip.generateAsync({type:"blob", compression: "DEFLATE", compressionOptions: { level: 9 }},(progress) => {
            onProgress(progress);
          }).then((content) => {
            var file1 = new File([content], new_name, {
              type: "application/zip",
            });
            this.isZipProgressBarVisible = false;
            this.zipProgress = false;
            if(this.zipProgressPercentage == '100.00%'){
              setTimeout(() => {
                this.zipProgressBar();
                setTimeout(() => {
                  _self.uploadObject([file1], _self.batchId);
                }, 100);
              }, 400);
            }
          }).catch((generateError) => {
              this.isPathTooLongError = true;
        });
    }
  }
}

   /**
   * (description): function is called to send skipped file details
   * (parameter) : fileName, filePath, batchId
   */
  public async skippedFileAPI(params): Promise<any> {
    this.auditLogActivity(params.fileName, this.errOutcome);
    this.uploadService.skippedFile(params).subscribe({
      next: result => {
        this.skippedFileRes = result;
      },error: err => {
        this.errUserDetails = err;
      }
    });
  }
  public openFacilityModal(): void {
    let notification = {
      displayUploadFile: false,
      displaySiteModal: true,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.isUploadInProgress = false;
    this.router.navigate(
      ['/uploadfiles'],         //nativate to upload files page
      { queryParams: { id: constantStrings.nextUpload } } //unique id passed 'nextUpload' along with path
    );
  }
  /**
  * (description): function called on click of back button
  * (parameter) : null
  * (memberof) : Component
  */
  onCancel() {
    var s3 = new AWS.S3();
    this.isUploadInProgress = false;
    this.applicationStateDataUnsubscription.unsubscribe();
    this.facilityStateDataUnsubscription.unsubscribe();
    this.surgeonStateDataUnsubscription.unsubscribe();
    this.auditLogActivityCancel();
    let fileUploadedStatus = false;
    this.uploadFileDetailObj = this.authService.getItem('uploadFileStatus');
    if(this.uploadFileDetailObj.length > 0){
      for(let i = 0;i < this.uploadFileDetailObj.length;i++){
          if(this.uploadFileDetailObj[i].batchId == this.batchId || this.uploadFileDetailObj[i].batchId == this.id){
          this.uploadFileDetailObj[i].fileStatus= constantStrings.CANCELLED;
          this.uploadId=this.uploadFileDetailObj[i].uploadId;
          this.key=this.uploadFileDetailObj[i].key;
          }
          if(this.uploadFileDetailObj[i].fileStatus == constantStrings.PROCESSING && (this.uploadFileDetailObj[i].batchId != this.batchId || this.uploadFileDetailObj[i].batchId != this.id) ) {
            fileUploadedStatus = true;
          }
      }
      this.sharedState.UploadProgressStateSubject(fileUploadedStatus);
      this.authService.setItem('uploadFileStatus', this.uploadFileDetailObj);
    }
    if(this.uploadId && this.uploadId != ''){
      this.sharedState.abortStatus=this.abortMultipartUpload(this.uploadId, environment.duuUploadBucket, s3, this.key);
    }
    // const body = { "batchId": this.batchId || this.id}
    // this.recallUploadAPI(body);
  }
  /**
  * (description): API call to get upload status
  * (parameter) : null
  * (memberof) : Component
  */


  //convert file size from byte to KB,MB, and GB accordingly
 /*  getFileSize(fileSize: number): number {
    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSize = parseFloat((fileSize / this.fileSizeUnit).toFixed(2));
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSize = parseFloat(
          (fileSize / this.fileSizeUnit / this.fileSizeUnit).toFixed(2)
        );
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSize = parseFloat(
          (fileSize / this.fileSizeUnit / this.fileSizeUnit / this.fileSizeUnit).toFixed(2)
        );
      }
    }

    return fileSize;
  } */

  //Find the unit of fileSize
  /* getFileSizeUnit(fileSize: number) {
    let fileSizeInWords = 'bytes';

    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit) {
        fileSizeInWords = 'bytes';
      } else if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSizeInWords = 'KB';
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = 'MB';
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = 'GB';
      }
    }

    return fileSizeInWords;
  } */
  /**
   * (description): function call to convert time
   * (parameter) : batchId
   * (memberof) : Component
   */
  timeConvert(n) {
    let num = n;
    let hours = (num / 60);
    this.timeInHours = Math.floor(hours);
    this.minutes = Math.round((hours - this.timeInHours) * 60);
  }
  /**
   * (description): To call audit log activity function
   * (memberof) : Component
  **/
  auditLogActivity(code, outcome){
    switch (code) {
      case constantStrings.CANCELLED:
        this.auditText = this.auditAction.UserActions.uploadCancelled;
        this.auditId = (this.batchId?this.batchId:this.id) + " " + this.surgeonRes.username + " " +
          this.displayApplication + " " + this.displayFacility;
      break;
      case statusCodeString.ERROR:
        this.auditText = this.auditAction.UserActions.uploadFailedTechnical;
        this.auditId = this.batchId + " " + this.surgeonRes.username + " " +
        this.displayApplication + " " + this.displayFacility;
      break;
      case statusCodeString.UPLOADED:
        this.auditText = this.auditAction.UserActions.uploadSuccessfull;
        this.auditId = this.batchId + " " + this.surgeonRes.username + " " +
          this.displayApplication +" " + this.displayFacility;
      break;
      case constantStrings.ALLSKIPPED:
        this.auditText = this.auditAction.UserActions.uploadFailedSkipped;
        this.auditId  = this.batchId + " " + this.surgeonRes.username + " " +
        this.displayApplication + " " + this.displayFacility;
      break;
      default:
        this.auditText = this.auditAction.UserActions.dicomValidation;
        this.auditId  = this.batchId + " " + code;
      break;
    }
    this.eventOutcome = outcome;
    let actionOutcome = {
      "userFacilityId" : this.userInfo.userFacilityId,
      "userName" : this.userInfo.userName,
      "action" : this.auditText+''+this.auditId,
      "eventOutCome" : this.eventOutcome
    }
    this.uploadService.auditLogActivity(actionOutcome).subscribe({
      next: result => {
        this.actionActivityDetails = result;
      }, error: err => {
        this.errUserDetails = err;
      }
    });
  }
  ngOnDestroy():void {
    this.applicationStateDataUnsubscription.unsubscribe();
    this.facilityStateDataUnsubscription.unsubscribe();
    this.surgeonStateDataUnsubscription.unsubscribe();
  }

   /**
     * @description To call the lambda function for patient view list page
     * @returns return the success or failure message.
     * @private
     * @param {*} mrnId
     * @param {*} surgeonName
     * @returns {*}
     * @memberof AwsS3Service
     */

	// tslint:disable-next-line:member-ordering
	public callLambdaFunction(batch, batchId) {
        let fileUploadedStatus = false;
        let uploadFileStorage = this.authService.getItem('uploadFileStatus');
        for(let statusFile of uploadFileStorage){
          if(statusFile.batchId == batchId){
            statusFile.fileStatus = constantStrings.UPLOADED;
            statusFile.percentDone = 100;
            statusFile.progressPercentage = "100%";
          }
          if(statusFile.fileStatus == constantStrings.PROCESSING && statusFile.batchId != batchId) {
            fileUploadedStatus = true;
          }
        }
        this.authService.setItem('uploadFileStatus', uploadFileStorage);
        this.progressPercentage = "100%";
        this.percentDone = 100;
        this.showUploadProgressModal = true;
        this.isProgressBarVisible = true;
        this.sharedState.uploadProgressPer(this.percentDone);
        this.isUploadInProgress = false;
        this.setCognitoConfigObject();
        let metaData;
        const clientParams: any = {
          region: environment.lambdaFunction.lambdaRegion,
          apiVersion: '2015-03-31'
        };

        if (environment.s3_endpoint) {
          clientParams.endpoint = environment.s3_endpoint;
        }
        const tokenID = this.authService.getCognitoAccessTokenFromStorage();
        const displayApplication = this.displaySelectionApplication ? this.displaySelectionApplication : this.displayApplication;
        metaData = { bucketName: environment.duuUploadBucket, fileKey: batch, tokenId: tokenID, selectedApplication: displayApplication, uploadFileDetails : this.uploadService.uploadFileDetailObj };
        let sample = new Lambda(clientParams);
        const pullParams = {
          FunctionName: environment.lambdaFunction.bridgeDuuProcessZipUpload,
          InvocationType: 'RequestResponse',
          LogType: 'None',
          Payload: JSON.stringify(metaData)
        };
        sample.invoke(pullParams, (err, data) => {
          this.sharedState.UploadProgressStateSubject(fileUploadedStatus);
          if (err !== null && errorMessages[err.code] !== '') {
            err.message = errorMessages[err.code];
            console.log(" lambda error ", err)
          }
          if (err) {
            console.log("lambda err ", err)
          } else {
            let result: any = data;
          }
        });
    }

  private uploadFailed(batchId) {
    let fileUploadedStatus = false;
    let uploadFileStorage = this.authService.getItem('uploadFileStatus');
    for(let statusFile of uploadFileStorage){
      if(statusFile.batchId == batchId){
        statusFile.fileStatus = constantStrings.FAILED;
        statusFile.errorReason = constantStrings.FAILED;
      }
      if(statusFile.fileStatus == constantStrings.PROCESSING && statusFile.batchId != batchId) {
        fileUploadedStatus = true;
      }
    }
    this.sharedState.UploadProgressStateSubject(fileUploadedStatus);
  this.authService.setItem('uploadFileStatus', uploadFileStorage);
  this.router.navigate(['/uploadfiles/uploadError'],
  {queryParams : { batchId:batchId}});
  }

  async auditLogActivityCancel(){
  await this.initiateAuditLogActivity();
  this.auditLogActivity(constantStrings.CANCELLED, this.successOutcome);
  }
  async initiateAuditLogActivity(){
    this.auditText = this.auditAction.UserActions.uploadInitiated;
    this.auditId  = this.batchId?this.batchId:this.id;
    this.eventOutcome = this.successOutcome;
    let actionOutcome = {
      "userFacilityId" : this.userInfo.userFacilityId,
      "userName" : this.userInfo.userName,
      "action" : this.auditText+''+this.auditId,
      "eventOutCome" : this.eventOutcome
    }
    return new Promise<any>((resolve, reject) => {
    this.uploadService.auditLogActivity(actionOutcome).subscribe({
      next: result => {
        this.actionActivityDetails = result;
        resolve(result);
      }, error: err => {
        this.errUserDetails = err;
        reject(err);
      }
    });
  });
  }

}

import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UploadService } from '../upload.service';
import { Modal, INITIAL_MODAL_STATE, AddSurgeonModal, INITIAL_ADDSURGEON_MODAL_STATE } from '../../shared/models/dialog.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchsurgeonComponent } from '../searchsurgeon/searchsurgeon.component';
import { TranslateService } from '@ngx-translate/core';
import { constantStrings } from 'src/app/shared/helpers/constants';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  public addModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;

  public facilityLinkModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;

  facilityMessage: string;
  isFileUpload:string = "1";
  public showFacilityModal: boolean;
  selectedFacility: any;
  oldSelectedFacilityId;
  isFacilitySelectionChanged;
  @ViewChild(SearchsurgeonComponent) SurgeonModel;
  
  @Input() modal: Modal = INITIAL_MODAL_STATE;
  searchText: string;
  facilitySelected;
  public facilityListRes:any;
  facilityId:number;
  totalCount:number = 0;
  isDisabled =true;
  @Output() event = new EventEmitter<boolean>()
  errorInfacilityList: any;
  facilityListSelected;
  notification;
  isFacilityTrue;
  facilityType: any;
  userInfo: any;
  isUnion:any = 0;
  noList: boolean = false;
  receiveList($event) {
    this.noList = $event;
  }
  constructor(
    private translateService: TranslateService,
    public router: Router,
    private uploadService:UploadService,
    private http:HttpClient,
    private spinnerService: NgxSpinnerService,
    private sharedState: SharedStateService,
    private authService: AuthService
  ) {
      translateService.setDefaultLang(constantStrings.defaultLanguage);
      this.showFacilityModal = true;
      this.sharedState.popupCancelStateData$.subscribe(response=>{
        if(response?.displaySiteModal){
          this.showFacilityModal= response.displaySiteModal;
          if (this.authService.getItem('userDetails')) {
            this.userInfo = this.authService.getItem('userDetails');
            this.getFacility(this.userInfo?.userFacilityId);
          }
        }
      });
     }

  ngOnInit(): void {
    this.translateService.get(['facility'])
      .subscribe((data) => {
        this.facilityMessage= data.facility.IDontSeeMsg;
      });
  }
  /**
  * @description should  call to get facility details api
  * @parameter facilityId
  * @returns component
  */
  public getFacility(facilityId): any {
    this.spinnerService.show();
    let apiParams: HttpParams = new HttpParams();
    apiParams = apiParams.append('linkedFacilityId',facilityId);
    apiParams = apiParams.append('isUnion', this.isUnion);
    apiParams = apiParams.append('isFileUpload', this.isFileUpload);
    this.uploadService.getFacilityLinkingDetails(apiParams).subscribe({
      next: result => {
        result.facilityLinkingResponses.forEach((element) => {
          element.facilityContactLead = this.uploadService.decodeValues(element.facilityContactLead);
          element.facilityEmail = this.uploadService.decodeValues(element.facilityEmail);
          element.facilityPhone = this.uploadService.decodeValues(element.facilityPhone);
          element.zipCode = this.uploadService.decodeValues(element.zipCode);
          element.facilityName = this.uploadService.decodeValues(element.facilityName);
          element.facilityImageUrl = this.uploadService.decodeValues(element.facilityImageUrl);
        });
        this.totalCount = 0;
        if(result && result.facilityLinkingResponses){
          this.totalCount=result.facilityLinkingResponses.length;
          this.facilityListRes = this.sortByName(result.facilityLinkingResponses);
          if(this.totalCount == 1){
            this.isDisabled = false;
            this.selectedFacility = this.facilityListRes[0];
            this.facilityListSelected=this.facilityListRes[0].facilityName;
            this.facilityId = this.facilityListRes[0].facilityId;
            this.facilityType = this.facilityListRes[0].facilityType;
          }
        }
        this.spinnerService.hide();
      }, error: err => {
        this.errorInfacilityList = err;
        this.spinnerService.hide();
      }
    }); 
  }
  /**
  * @description should  call to sort function
  * @parameter facility list
  * @returns component
  */
  sortByName = function(users) {
    return users.sort((a,b) => a.facilityName > b.facilityName ? 1 : -1)
  };
  /**
  * @description method called on click of back button or close icon
  * @parameter facility list
  * @returns component
  */
  public onCancel(): void {
    let notification = {
      displayUploadFile: true,
      displaySiteModal: false,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
    this.isDisabled= true;
    this.facilitySelected=null;
  }
  /**
  * @description method called on click of next button
  * @parameter facility list
  * @returns component
  */
  public selectSurgeon(): void {
    //Receive displayed facility Id from shared service
    this.sharedState.facilityStateData$.subscribe(response => {
      this.oldSelectedFacilityId = response.displayFacilityId;
    })
    this.isFacilitySelectionChanged = false;
    //If newly selected facility Id is different isFacilitySelectionChanged will become true as selection is changed
    if (this.selectedFacility.facilityId != this.oldSelectedFacilityId) {
      this.isFacilitySelectionChanged = true;
    }
    this.showFacilityModal= false;
    this.notification= {
      displaySelectionfacility:this.facilityListSelected,
      displayFacilityId: this.facilityId,
      displayFacilityType: this.facilityType,
      selectedFacility: this.selectedFacility
    }
    this.isFacilityTrue={
      isDisplaySelectionfacility:true,
      isDisplaySelectionSurgeon:false,
      isDisplaySelectionApplication:false,
      isUploadFailed:false
    }
    //Passing selected facility value to state service
    this.sharedState.setDashboardStateData(this.isFacilityTrue);
    this.sharedState.facilitySelectionData(this.notification);
    let notification = {
      displaySiteModal: false,
      displaySurgeonModal: true,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.addModalUi = {
      display: 'block',
      header: 'Add Surgeon',
      content: '',
      okButton: 'Save',
      cancelButton: 'Cancel',
      submitFunction: () => { },
      closeFunction: () => {
      }
    };
  }
  /**
  * @description method called on click of dont see facility link
  * @parameter facility list
  * @returns component
  */
  public selectFacilityLink(): void {
    this.noList = true;
       this.facilityLinkModalUi = {
          display: 'block',
          header: 'Add Surgeon',
          content: '',
          okButton: 'Save',
          cancelButton: 'Cancel',
          submitFunction: () => { },
          closeFunction: () => {
          }
        };
      }
  /**
  * @description method called on choosing a facility in modal
  * @parameter facility list
  * @returns component
  */
  onSelect(_index: number,facility){
    this.facilitySelected= facility.facilityId;
    this.selectedFacility = facility;
    this.facilityId = facility.facilityId;
    this.facilityListSelected=facility.facilityName;
    this.facilityType = facility.facilityType;
    this.isDisabled = false;
  }
  
}

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { INITIAL_MODAL_STATE, Modal, AddSurgeonModal, INITIAL_ADDSURGEON_MODAL_STATE } from '../../shared/models/dialog.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadService } from '../upload.service';
import { TranslateService } from '@ngx-translate/core';
import { constantStrings } from 'src/app/shared/helpers/constants';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { ApplicationComponent } from '../application/application.component';

@Component({
  selector: 'app-searchsurgeon',
  templateUrl: './searchsurgeon.component.html',
  styleUrls: ['./searchsurgeon.component.scss']
})
export class SearchsurgeonComponent implements OnInit {
  public addModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;
  public linkModalUi: AddSurgeonModal = INITIAL_ADDSURGEON_MODAL_STATE;
  @ViewChild(ApplicationComponent) ApplicationModel;
  @Input() facilityId: number;
  surgeonId: number;
  surgeonMessage: string;

  @Input() modal: Modal = INITIAL_MODAL_STATE;
  searchText;
  surgeonSelected;
  public surgeonListRes: any;
  isDisabled: boolean;
  totalcount: number;
  displaySelectionFacility;
  notification;
  surgeonListSelected;
  isFacilitySelected = false;
  isSurgeonTrue;
  public showSurgeonModal: boolean;
  @Input() isFacilitySelectionChanged: any;
  @Output() event = new EventEmitter<boolean>()
  errorInsurgeonList: any;
  selectedSurgeonFName: string;
  selectedSurgeonId: number;
  selectedSurgeonLName: string;
  targetSurgeonId: number;
  noList: boolean = false;
  highlightSelectedSurgeon: string = '';

  receiveList($event) {
    this.noList = $event;
  }
  constructor(
    private translateService: TranslateService,
    public router: Router,
    private uploadService: UploadService,
    private http: HttpClient,
    private spinnerService: NgxSpinnerService,
    private sharedState: SharedStateService) {
    translateService.setDefaultLang(constantStrings.defaultLanguage);
    this.showSurgeonModal = true;
    this.sharedState.popupCancelStateData$.subscribe(response => {
      if (response?.displaySurgeonModal) {
        this.showSurgeonModal = response.displaySurgeonModal;
      }
    });
    //Recieving selected facility value from state service
    this.sharedState.facilityStateData$.subscribe(response => {
      this.displaySelectionFacility = response.displaySelectionfacility;
      if (this.displaySelectionFacility) this.getSurgeon(response.displayFacilityId);
    })

    this.sharedState.uploadStateData$.subscribe(response => {
      this.isFacilitySelected = response.isDisplaySelectionfacility;
    })
  }

  ngOnInit(): void {
    this.translateService.get(['surgeon'])
      .subscribe((data) => {
        this.surgeonMessage = data.surgeon.IDontSeeMsg;
      });
    if (this.surgeonSelected == null) {
      this.isDisabled = true;
    }
  }
  /**
  * (description): method called on click of next button
  * (parameter) : null
  * (memberof) : Component
  */
  public selectApplication(): void {
    this.surgeonId = this.targetSurgeonId;
    this.showSurgeonModal = false;
    this.isFacilitySelected = false;
    this.isSurgeonTrue = {
      isDisplaySelectionSurgeon: true,
      isDisplaySelectionfacility: false,
      isDisplaySelectionApplication: false,
      isUploadFailed: false
    }
    //Passsing selected surgeon to state service 
    this.sharedState.setDashboardStateData(this.isSurgeonTrue);
    if(this.surgeonListRes.length==1){
      this.surgeonListSelected = this.surgeonListRes[0].firstname + " " + this.surgeonListRes[0].lastname;
      this.selectedSurgeonFName = this.surgeonListRes[0].firstname;
      this.selectedSurgeonLName = this.surgeonListRes[0].lastname;
      this.surgeonId = this.surgeonListRes[0].username;
      this.notification= {
        displaySelectionSurgeon:this.surgeonListSelected,
        displaySurgeonId: this.surgeonListRes[0].username,
        displaySurgeonFName: this.selectedSurgeonFName,
        displaySurgeonLName: this.selectedSurgeonLName,
        surgeonEmail: this.surgeonListRes[0].email
      }
      this.sharedState.surgeonSelectionData(this.notification)
    }
    //Pass surgeon Id when single surgeon is present
    if(this.totalcount==1){
      let surgeon = this.surgeonListRes[0];     
      let surgeonObject= {
        surgeon: surgeon,
        surgeonSelected: this.surgeonSelected,
        targetSurgeonId: this.targetSurgeonId,
      }
      this.sharedState.surgeonObjectData(surgeonObject);
    }
    let notification = {
      displaySiteModal: false,
      displaySurgeonModal: false,
      displayApplicationModal: true,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.addModalUi = {
      display: 'block',
      header: 'Add Surgeon',
      content: '',
      okButton: 'Save',
      cancelButton: 'Cancel',
      submitFunction: () => { },
      closeFunction: () => {
      }
    };
  }

  //Function to get surgeon API response
  public getSurgeon(facilityId): any {
    this.sharedState.surgeonObjectStateData$.subscribe(response => {       //get surgeon selected stored in state service
      this.surgeonSelected = response.surgeonSelected;
    })
    this.spinnerService.show();
    this.uploadService.getsurgeonlist(facilityId).subscribe({
      next: result => {
        if(result && result.userDetails.length > 0){
          result.userDetails.forEach((element) => {
            element.adminUsername = this.uploadService.decodeValues(element.adminUsername);
            element.countryCode = this.uploadService.decodeValues(element.countryCode);
            element.email = this.uploadService.decodeValues(element.email);
            element.firstname = this.uploadService.decodeValues(element.firstname);
            element.lastname = this.uploadService.decodeValues(element.lastname);
            element.phonenumber = this.uploadService.decodeValues(element.phonenumber);
            element.username = this.uploadService.decodeValues(element.username);
          });
          this.surgeonListRes = result.userDetails.sort(function(a, b) {
            var aFirstChar = a.firstname.charAt(0);
            var bFirstChar = b.firstname.charAt(0);
            if (aFirstChar > bFirstChar) {
              return 1;
            } else if (aFirstChar < bFirstChar) {
              return -1;
            } else {
              var aLastChar = a.lastname.charAt(0);
              var bLastChar = b.lastname.charAt(0);
              if (aLastChar > bLastChar) {
                return 1;
              } else if (aLastChar < bLastChar) {
                return -1;
              } else {
                return 0;
              }    
            }
          });
        }
        this.totalcount= result.userDetails.length;
        if(this.totalcount == 1){
          this.isDisabled = false;
        }
        this.spinnerService.hide();
        //If facility selection is changed disable next button
        if (this.isFacilitySelectionChanged === true && this.totalcount > 1) {
          this.isDisabled = true;
        }
      },error: err => {
        this.errorInsurgeonList = err;
        this.spinnerService.hide();
      }
    });
  }
  /**
  * (description): method called on click of dont see surgeon link
  * (parameter) : null
  * (memberof) : Component
  */
  public selectSurgeonlink(): void {
    this.noList = true;
    this.linkModalUi = {
      display: 'block',
      header: 'Add Surgeon',
      content: '',
      okButton: 'Save',
      cancelButton: 'Cancel',
      submitFunction: () => { },
      closeFunction: () => {
      }
    };
  }
  /**
  * (description): method called on click of back button
  * (parameter) : null
  * (memberof) : Component
  */
  public onCancel(): void {
    this.isFacilitySelected = false;
    this.showSurgeonModal = false;
    let notification = {
      displayUploadFile: false,
      displaySiteModal: true,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
    this.isDisabled = true;
  }
  /**
  * (description): method called on click of close icon
  * (parameter) : null
  * (memberof) : Component
  */
  public popupClose(): void {
    this.isFacilitySelected = false;
    this.showSurgeonModal = false;
    let notification = {
      displayUploadFile: true,
      displaySiteModal: false,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    this.modal = INITIAL_MODAL_STATE;
    this.modal.display = 'none';
    this.isDisabled = true;
    let surgeonObject = {
      surgeonSelected: null,
    }
    this.sharedState.surgeonObjectData(surgeonObject);
  }

  //Function for selecting a surgeon
  onSelect(_index: number,surgeon){
    this.targetSurgeonId = surgeon.username;
    if(this.totalcount>1){
    this.surgeonSelected= surgeon.firstname+ " " + surgeon.lastname;
    this.highlightSelectedSurgeon = surgeon.email;
    surgeon.surgeonId = this.targetSurgeonId;       //remove this line when CLP portal API has surgeon Id in response
    let surgeonObject= {
      surgeon: surgeon,
      surgeonSelected: this.surgeonSelected,
      targetSurgeonId: this.targetSurgeonId,
    }
    this.sharedState.surgeonObjectData(surgeonObject);      //Pass selected surgeon Id to other components
    this.surgeonListSelected=surgeon.firstname+ " " + surgeon.lastname;
    this.isDisabled= false;
    this.selectedSurgeonId= surgeon.surgeonId;
    this.selectedSurgeonFName = surgeon.firstname;
    this.selectedSurgeonLName = surgeon.lastname;
    this.notification= {
      displaySelectionSurgeon:this.surgeonListSelected,
      displaySurgeonId: this.targetSurgeonId,
      displaySurgeonFName: this.selectedSurgeonFName,
      displaySurgeonLName: this.selectedSurgeonLName,
      surgeonEmail: surgeon.email
    }
    this.sharedState.surgeonSelectionData(this.notification)
    }
  }
}

import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantStrings } from '../shared/helpers/constants';
import { INITIAL_MODAL_STATE, Modal } from '../shared/models/dialog.model';
import { errorResponse, settingsData, settingsResponse } from '../shared/models/user.model';
import { AuthService } from '../shared/services/auth.service';
import { SharedStateService } from '../shared/services/shared-state.service';
import { UploadService } from '../uploadfiles/upload.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, AfterContentChecked {
  isSurgeonTrue;
  inputSuccess: boolean = true;
  inputUnsuccess: boolean = true;
  toggleFlag: boolean = false;
  myFlagForSlideToggle: boolean;
  successNum:string | number;
  unsuccessNum: string | number;
  apiError: errorResponse;
  getSettingsData: settingsData;
  userInfo: any;
  getToggleValue: boolean;
  putSettingsData: settingsResponse;
  resetData = {
    isFacilityWide: false,
    successDays: 14,
    errorDays: 14
  }
  actionActivityDetails: settingsResponse;
  auditAction: any;
  auditText: string | number;
  auditId: string | number;
  SettingsData:any;
  public waringModal: Modal = INITIAL_MODAL_STATE;
  isToggleChecked=false;

  constructor(
    private sharedState: SharedStateService,
    private uploadService: UploadService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
      this.getSettingsDetails(this.userInfo.userFacilityId);
    }
    this.isSurgeonTrue = {
      isDisplaySelectionfacility: false,
      isDisplaySelectionSurgeon: false,
      isDisplaySelectionApplication: false,
      isUploadFailed: false,
      isHelpScreen: false
    }
    this.sharedState.setDashboardStateData(this.isSurgeonTrue);
    this.translateService.get(['SettingsData']).subscribe((data) => {
      this.SettingsData = data.SettingsData;
    });
  }
  ngAfterContentChecked(): void {
    if (this.getSettingsData) {
      this.daysValidation();
    }
  }

  /**
   * (description): Function call to get settings details
   * (parameter) : facilityId
   * (memberof) : SettingsComponent
   */
  getSettingsDetails(facilityId) {
    this.spinnerService.show();
    this.uploadService.getSettingsDetails(facilityId).subscribe({
      next: result => {
        this.getSettingsData = result;
        this.spinnerService.hide();
        this.successNum = result.successDays;
        this.unsuccessNum = result.errorDays;
        this.myFlagForSlideToggle = result.isFacilityWide;
      }, error: err => {
        this.apiError = err;
        this.spinnerService.hide();
      }
    });
  }
  /**
   * (description): Function call to validate success days and error days
   * (parameter) : id, event
   * (memberof) : SettingsComponent
   */
  daysValidation() {
    if (this.successNum >= 1 && this.successNum <= 30) {
      this.inputSuccess = true;
      this.saveDisable();
    } else {
      this.inputSuccess = false;
      this.saveDisable();
    }
    if (this.unsuccessNum >= 1 && this.unsuccessNum <= 30) {
      this.inputUnsuccess = true;
      this.saveDisable();
    } else {
      this.inputUnsuccess = false;
      this.saveDisable();
    }
  }
  /**
   * (description): Function call to set toggle values
   * (parameter) : event
   * (memberof) : SettingsComponent
   */
  setToggleValue(event) {
    this.getToggleValue = event.checked;
    if (event.checked) {
      this.isToggleChecked=true;
      this.myFlagForSlideToggle = true;
      this.waringModal = {
        display: 'block',
        header: '',
        content: this.SettingsData.WarningMsg,
        okButton:this.SettingsData.Yes ,
        cancelButton: this.SettingsData.Cancel,
        submitFunction: () => {
          this.myFlagForSlideToggle = true;
          this.closeWarningModal();
        },
        closeFunction: () => {
          this.closeWarningModal();
          this.myFlagForSlideToggle = false;
        },
        data: '',
      };
    } else {
      this.myFlagForSlideToggle = false;
    }
    this.saveDisable();
  }
  /**
   * (description): Function call to disable save button
   * (parameter) : null
   * (memberof) : SettingsComponent
   */
  saveDisable() {
    if (this.getSettingsData) {
      if (this.getSettingsData.isFacilityWide != this.myFlagForSlideToggle) {
        this.toggleFlag = true;
      } else if (this.getSettingsData.errorDays != this.unsuccessNum) {
        this.toggleFlag = true;
      } else if (this.getSettingsData.successDays != this.successNum) {
        this.toggleFlag = true;
      } else {
        this.toggleFlag = false;
      }
    }
  }
  /**
   * (description): Function call to reset settings details
   * (parameter) : null
   * (memberof) : SettingsComponent
   */
  resetToDefault() {
    this.successNum = this.resetData.successDays;
    this.unsuccessNum = this.resetData.errorDays;
    this.myFlagForSlideToggle = this.resetData.isFacilityWide;
    this.inputSuccess = true;
    this.inputUnsuccess = true;
    this.toggleFlag = true;

  }
  /**
   * (description): Function call to put settings details
   * (parameter) : null
   * (memberof) : SettingsComponent
   */
  putSettingsDetails() {
    const settingsPayload = {
      userFacilityId: this.userInfo.userFacilityId,
      isFacilityWide: this.myFlagForSlideToggle ? 1 : 0,
      successDays: this.successNum,
      errorDays: this.unsuccessNum
    }
    this.spinnerService.show();
    this.uploadService.putSettingsDetails(settingsPayload).subscribe({
      next: result => {
        this.putSettingsData = result;
        this.getSettingsDetails(this.userInfo.userFacilityId);
        this.toggleFlag = false;
        this.spinnerService.hide();
        if (this.getSettingsData.isFacilityWide != this.myFlagForSlideToggle) {
          this.auditLogActivity(constantStrings.TOGGLE);
        } 
        if (this.getSettingsData.errorDays != this.unsuccessNum) {
          this.auditLogActivity(constantStrings.UNSUCCESS);
        } 
        if (this.getSettingsData.successDays != this.successNum) {
          this.auditLogActivity(constantStrings.SUCCESS);
        }   
      }, error: err => {
        this.apiError = err;
        this.spinnerService.hide();
        if (this.getSettingsData.isFacilityWide != this.myFlagForSlideToggle) {
          this.auditLogActivity(constantStrings.TOGGLE);
        } 
        if (this.getSettingsData.errorDays != this.unsuccessNum) {
          this.auditLogActivity(constantStrings.UNSUCCESS);
        } 
        if (this.getSettingsData.successDays != this.successNum) {
          this.auditLogActivity(constantStrings.SUCCESS);
        }   
      }
    });      
  }

  /**
   * (description): To call audit log activity function
   * (memberof) : Component
  **/
  auditLogActivity(str){
    if (str == constantStrings.TOGGLE) {
      this.auditText = this.auditAction.UserActions.viewSettings;
      this.auditId = this.userInfo?.userFacilityId + " " + this.myFlagForSlideToggle;
    } 
    if (str == constantStrings.UNSUCCESS) {
      this.auditText = this.auditAction.UserActions.settingUnsuccess;
      this.auditId = this.unsuccessNum;
    } 
    if (str == constantStrings.SUCCESS) {
      this.auditText = this.auditAction.UserActions.settingSuccess;
      this.auditId = this.successNum;
    }
    let actionOutcome = {
      "userFacilityId" : this.userInfo.userFacilityId,
      "userName" : this.userInfo.userName,
      "action" : this.auditText+''+this.auditId,
      "eventOutCome" : this.putSettingsData ? 1 : 0
    }
    this.uploadService.auditLogActivity(actionOutcome).subscribe({
      next: result => {
        this.actionActivityDetails = result;
      }, error: err => {
        this.apiError = err;
      }
    });
  }

  /*
   * @description this is to close warning modal
   * @memberof SettingComponent
   */
  closeWarningModal() {
    this.waringModal = INITIAL_MODAL_STATE;
    this.isToggleChecked=false;
  }

}
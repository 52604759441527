import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FilterModal, HistoryDetailsModal, INITIAL_FILTERMODAL_STATE, INITIAL_HistoryDetailsModal_STATE, INITIAL_MODAL_STATE, Modal } from '../shared/models/dialog.model';
import { SharedStateService } from '../shared/services/shared-state.service';
import { SelectionModel } from '@angular/cdk/collections';
import { HistoryfilterComponent } from './historyfilter/historyfilter.component';
import { ROLE, constantStrings, statusCode, statusCodeString } from '../shared/helpers/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadService } from '../uploadfiles/upload.service';
import { AuthService } from '../shared/services/auth.service';
import { errorResponse, HistoryData } from '../shared/models/user.model';
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  isSurgeonTrue;
  isDisabled = false;
  searchTextOnPlaceHolder: string = 'Search';
  searchiconText: string = 'Search Icon'
  displayedColumns: string[] = ['createdDate', 'uploadId', 'uploaderFirstName', 'patientName', 'surgeonFirstName', 'targetFacilityName', 'uploadStatus'];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<any>(false, []);
  @Input() modal: Modal = INITIAL_MODAL_STATE;
  public addModalUi: HistoryDetailsModal = INITIAL_HistoryDetailsModal_STATE;
  @ViewChild(HistoryfilterComponent) FilterModel;
  public filterModal: FilterModal = INITIAL_FILTERMODAL_STATE;
  selectedFilters;
  selectedSurgeon = [];
  selectedSite = [];
  selectedUploader = [];
  selectedPatient = [];
  selectedStatus = [];
  newList = [];
  selectedStartDate: Date;
  selectedEndDate: Date;
  gradientFlag: boolean = true;
  startDateGradient: boolean = true;
  endDateGradient: boolean = true;
  uploaderGradient: boolean = true;
  patientGradient: boolean = true;
  surgeonGradient: boolean = true;
  siteGradient: boolean = true;
  statusGradient: boolean = true;
  StatusCodeUpload: number;
  StatusCodeProcessing: number;
  StatusCodeComplete: number;
  StatusCodeError: number;
  StatusCodeStringUpload: string;
  StatusCodeStringProcessing: string;
  StatusCodeStringComplete: string;
  StatusCodeStringError: string;
  startEndGradient: boolean;
  public today: Date = new Date();
  filterFlag: boolean;
  errorRes: errorResponse;
  historyData: any;
  userInfo: any;
  historyObject: HistoryData;
  searchText: string;
  pagelimit: number;
  isdayChecked: boolean = false;
  selectedUploadData: string;
  @Output() successId: EventEmitter<any> = new EventEmitter<any>();
  viewDetailsFlag: boolean = false;
  historyCount: number;
  filterActive: boolean = false;
  storeHistoryData: any;
  pageStart: number = 0;
  errUserDetails: any;
  actionActivityDetails: any;
  auditAction: any;
  auditText: string | number;
  auditId: string | number;
  userRole: string = ROLE.SNN_HIL_MANAGER;
  isPatientRecordLinkActive: boolean = false;
  isBatchRecordLinkActive: boolean = false;
  patientRecord = constantStrings.patientRecord;
  batchRecord = constantStrings.batchRecord;
  pageName = '';
  characterLimit=constantStrings.characterLimit;
  //receives the selected filter values sent from filedashboardfilter component
  receiveList($event) {
    this.selectedFilters = $event;
  }

  constructor(private sharedState: SharedStateService,
    private _liveAnnouncer: LiveAnnouncer,
    private uploadService: UploadService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private datePipe: DatePipe) {
    this.StatusCodeUpload = statusCode.UPLOADED;
    this.StatusCodeProcessing = statusCode.PROCESSING;
    this.StatusCodeComplete = statusCode.COMPLETE;
    this.StatusCodeError = statusCode.ERROR;
    this.StatusCodeStringUpload = statusCodeString.UPLOADED
    this.StatusCodeStringProcessing = statusCodeString.PROCESSING
    this.StatusCodeStringComplete = statusCodeString.COMPLETE
    this.StatusCodeStringError = statusCodeString.ERROR
    this.viewDetailsFlag = false;
    this.pagelimit = statusCode.PageLimit;
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
      this.historyObject = {
        linkedFacilityId: this.userInfo.userFacilityId,
        searchText: this.searchText ? this.searchText : '',
        uploadId: [],
        patientName: [],
        uploaderId: [],
        surgeonId: [],
        targetFacilityId: [],
        uploadStatus: [],
        fromCreatedDate: null,
        toCreatedDate: null,
        page: 0,
        limit: this.pagelimit,
        column: constantStrings.createdDate,
        sortorder:'desc'
      }
      this.historyDetailsAPI(this.historyObject);
    }

  }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    this.viewDetailsFlag = false;
    this.isSurgeonTrue = {
      isDisplaySelectionfacility: false,
      isDisplaySelectionSurgeon: false,
      isDisplaySelectionApplication: false,
      isUploadFailed: false,
      isHelpScreen: false
    }
    this.sharedState.setDashboardStateData(this.isSurgeonTrue);
    this.isPatientRecordLinkActive = true;
  }

  ngAfterViewInit() {
    this.viewDetailsFlag = false;
  }

  /**
  * (description): calling API to read an error for a particular record sucessfully
  * (parameter) : uploadId, isRead
  * (memberof) : HistoryComponent
  */
  public historyDetailsAPI(params): any {
    this.spinnerService.show();
    this.uploadService.historyDetails(params).subscribe({
      error: err => {
        //If API fails setting the historyCount to 0 for displaying error message
        this.historyCount = 0;
        if (this.searchText) {
          this.auditLogActivity(constantStrings.SEARCH);
        }
        if (this.filterActive) {
          this.auditLogActivity(constantStrings.FILTER);
        }
        this.errorRes = err;
        this.spinnerService.hide();
      },
      next: result => {
        this.historyCount = 0;
        if (result && result.historyDetails) {
          this.historyData = result;
          for (let i = 0; i < result.historyDetails.length; i++) {
            const auditlogData = result.historyDetails[i];
            const utcDatetime = auditlogData.createdDate;
            const utcDate = new Date(utcDatetime);
            const createdDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000); // Convert to local timezone
            result.historyDetails[i].createdDate = createdDate;
            //Converting data object to string format to display as per figma
            let fixedDatetime = createdDate.toISOString();
            result.historyDetails[i]['createdDateDisplay'] = this.datePipe.transform(fixedDatetime, 'MM/dd/yyyy hh:mm:ss a');
          }
          if (this.historyObject.page == 0) {
            this.storeHistoryData = result.historyDetails;
          } else {
            this.storeHistoryData = [...this.storeHistoryData, ...result.historyDetails]
          }
          this.dataSource = new MatTableDataSource(this.storeHistoryData);
          setTimeout(() => {
            console.log('patient batch',this.dataSource.sort)
          }, 100);
          if (result.historyDetails.length) {
            this.historyCount = this.historyData.historyDetails.length
          }
        }
        if (this.searchText) {
          this.auditLogActivity(constantStrings.SEARCH);
        }
        if (this.filterActive) {
          this.auditLogActivity(constantStrings.FILTER);
        }
        this.spinnerService.hide();
      }
    });
  }

  /**
  * (description): when row is selected from table this function is called
  * (parameter) : event, item
  * (memberof) : HistoryComponent
  */
  dataSelection(event, listItem) {
    this.selectedUploadData = listItem.uploadId;
  }

  /**
  * (description): when 24hrs checkbox is clicked this function is called
  * (parameter) : null
  * (memberof) : HistoryComponent
  */
  dayCheckChecked() {
    this.viewDetailsFlag = false;
    this.isDisabled = false;
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedUploader = [];
    this.selectedPatient = [];
    this.selectedSurgeon = [];
    this.selectedSite = [];
    this.selectedStatus = [];
    if (this.filterActive) {
      this.filterActive = false;
      this.historyObject.fromCreatedDate = null;
      this.historyObject.toCreatedDate = null;
      this.historyObject.uploadId = [];
      this.historyObject.uploaderId = [];
      this.historyObject.surgeonId = [];
      this.historyObject.targetFacilityId = [];
      this.historyObject.uploadStatus = [];
    }
    if (this.FilterModel != undefined) {
      this.FilterModel.clearAll();
    }
    this.isdayChecked = !this.isdayChecked;
    if (this.isdayChecked == true) {
      let twentyfourHours = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
      this.historyObject.fromCreatedDate = this.formatedDate(twentyfourHours, constantStrings.STARTDATE);
      this.historyObject.toCreatedDate = this.formatedDate(new Date(new Date().getTime()), constantStrings.ENDDATE);
      if (this.isPatientRecordLinkActive) {
        this.historyDetailsAPI(this.historyObject);
      }
      else {
        this.historyObject.uploadStatus = [statusCode.UPLOADPENDING,statusCode.FAILED, statusCode.CORRUPTED, statusCode.PARTIALLY_CORRUPTED];
        this.uploadService.batchUploadSubject.next(this.historyObject);
      }
    }
    if (this.isdayChecked == false) {
      this.historyObject.fromCreatedDate = null;
      this.historyObject.toCreatedDate = null;
      if (this.isPatientRecordLinkActive) {
        this.historyDetailsAPI(this.historyObject);
      }
      else {
        this.uploadService.batchUploadSubject.next(this.historyObject);
      }
    }
  }
  /**
  * (description): when date is choosed in filter this function is called
  * (parameter) : date
  * (memberof) : HistoryComponent
  */
  formatedDate(str, dateFlag) {
    if (str == null) return null;
    let newStr = new Date(str);
    let changedUtcDate;
    if (str && dateFlag == constantStrings.STARTDATE) {
      let utcStartTime = new Date(
        newStr.getTime() + newStr.getTimezoneOffset() * 60000
      );
      changedUtcDate = utcStartTime.toISOString();
    } else if (str && dateFlag == constantStrings.ENDDATE) {
      newStr.setHours(0, 0, 0, 0);
      let endOfDay =
        newStr.getTime() +
        (24 * 60 * 60 * 1000 - 1) +
        newStr.getTimezoneOffset() * 60000;
      changedUtcDate = new Date(endOfDay).toISOString();
    }
    let formatedDate = formatDate(
      changedUtcDate,
      'MM/dd/yyyy HH:mm:ss',
      'en-US'
    );
    console.log('timezone',Intl.DateTimeFormat().resolvedOptions().timeZone)
    return formatedDate;
  }

  /**
  * (description): when clicked on search button this function is called
  * (parameter) : null
  * (memberof) : HistoryComponent
  */
  searchDashboard() {
    this.historyObject.searchText = this.searchText;
    this.historyObject.page = this.pageStart;
    if (this.isPatientRecordLinkActive) {
      this.historyDetailsAPI(this.historyObject);
    }
    else {
      this.historyObject.uploadStatus=[statusCode.UPLOADPENDING, statusCode.FAILED, statusCode.CORRUPTED,  statusCode.PARTIALLY_CORRUPTED ];
      this.uploadService.batchUploadSubject.next(this.historyObject);
    }
  }
  /**
  * (description): when clicked on show more button this function is called
  * (parameter) : null
  * (memberof) : HistoryComponent
  */
  ShowMore() {
    this.historyObject.page = this.historyObject.page + 1;
    this.historyObject.searchText = this.searchText;
    this.historyDetailsAPI(this.historyObject);
  }

  //This method is for sorting table
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
    this.historyObject.sortorder = sortState.direction;
    const sortMapping = {
      createdDate: constantStrings.createdDate,
      uploadId: constantStrings.uploadId,
      uploaderFirstName: constantStrings.UPLOADER,
      patientName: constantStrings.PATIENT,
      surgeonFirstName: constantStrings.surgeon,
      targetFacilityName: constantStrings.SITE,
      uploadStatus: constantStrings.UPLOADSTATUS
    };
    
    if (sortMapping.hasOwnProperty(sortState.active)) {
      this.historyObject.column = sortMapping[sortState.active];
    }
    this.historyDetailsAPI(this.historyObject);
  }

  //This method is called on click of View Details button
  public viewDetails(): void {
    let notification = {
      uploadId: this.selectedUploadData
    }
    this.sharedState.historyDetails(notification);
    this.viewDetailsFlag = true;
    this.successId.emit(this.selectedUploadData);
    this.pageName = constantStrings.historyPage;
    this.addModalUi = {
      display: 'block',
      header: "",
      content: this.selectedUploadData,
      closeButton: "",
      closeFunction: () => { }
    };
  }

  //This method is called to open Filter modal
  public openFilter(): void {
    this.filterFlag = true;
    this.filterModal = {     //modal for filter dropdown
      display: 'block',
      header: 'Filter By',
      content: '',
      doneButton: 'Done',
      submitFunction: () => { },
      okButton: null,
      cancelButton: null,
      closeFunction: undefined,
    };
  }
  // Function that displays the selected filter items
  displayFilterItems() {
    this.viewDetailsFlag = false;
    this.isDisabled = false;
    this.isdayChecked = false;
    if (this.isPatientRecordLinkActive) {
    if (this.selectedFilters.status && this.selectedFilters.status.length != 0) {
      this.historyObject.uploadStatus = [];
      for (let status of this.selectedFilters.status) {
        if (status.status.toLowerCase() == statusCodeString.UPLOADED && !this.historyObject.uploadStatus.includes(statusCode.UPLOADED)) this.historyObject.uploadStatus.push(this.StatusCodeUpload);
        if (status.status.toLowerCase() == statusCodeString.PROCESSING && !this.historyObject.uploadStatus.includes(statusCode.PROCESSING)) this.historyObject.uploadStatus.push(this.StatusCodeProcessing);
        if (status.status.toLowerCase() == statusCodeString.COMPLETE && !this.historyObject.uploadStatus.includes(statusCode.COMPLETE)) this.historyObject.uploadStatus.push(this.StatusCodeComplete);
        if (status.status.toLowerCase() == statusCodeString.ERROR && !this.historyObject.uploadStatus.includes(statusCode.ERROR)) this.historyObject.uploadStatus.push(this.StatusCodeError);
        if (status.status.toLowerCase() == statusCodeString.UNPROCESSED && !this.historyObject.uploadStatus.includes(statusCode.UNPROCESSED)) this.historyObject.uploadStatus.push(statusCode.UNPROCESSED);
        if (status.status.toLowerCase() == statusCodeString.RECALLED && !this.historyObject.uploadStatus.includes(statusCode.RECALLED)) this.historyObject.uploadStatus.push(statusCode.RECALLED);
        if (status.status.toLowerCase() == statusCodeString.GROUPED && !this.historyObject.uploadStatus.includes(statusCode.GROUPED)) this.historyObject.uploadStatus.push(statusCode.GROUPED);
      }
    } else {
      this.historyObject.uploadStatus = [];
    }
   }
   else{
    if (this.selectedFilters.status && this.selectedFilters.status.length != 0) {
      this.historyObject.uploadStatus = [];
      for (let status of this.selectedFilters.status) {
        if (status.status.toLowerCase() == statusCodeString.PENDING.toLowerCase() && !this.historyObject.uploadStatus.includes(statusCode.UPLOADPENDING)) this.historyObject.uploadStatus.push(statusCode.UPLOADPENDING);
        if (status.status.toLowerCase() == statusCodeString.ERROR.toLowerCase() && !this.historyObject.uploadStatus.includes(statusCode.FAILED)) this.historyObject.uploadStatus.push(statusCode.FAILED);
        if (status.status.toLowerCase() == statusCodeString.CORRUPTED.toLowerCase() && !this.historyObject.uploadStatus.includes(statusCode.CORRUPTED)) this.historyObject.uploadStatus.push(statusCode.CORRUPTED);
        if (status.status.toLowerCase() == statusCodeString.PARTIALLY_CORRUPTED.toLowerCase() && !this.historyObject.uploadStatus.includes(statusCode.PARTIALLY_CORRUPTED)) this.historyObject.uploadStatus.push(statusCode.PARTIALLY_CORRUPTED);  
      }
    } else {
      this.historyObject.uploadStatus = [statusCode.UPLOADPENDING,statusCode.FAILED,statusCode.CORRUPTED, statusCode.PARTIALLY_CORRUPTED ];
    }
   }
    //Asigning filter values to api parameters
    this.historyObject.uploaderId = this.selectedFilters.uploader;
    //this.historyObject.uploadId = this.selectedFilters.patient;
    this.historyObject.patientName = this.selectedFilters.patient
    this.historyObject.surgeonId = this.selectedFilters.surgeon;
    this.historyObject.targetFacilityId = this.selectedFilters.site;
    let startDate = null, endDate = null;
    if(this.selectedFilters?.startDate){
       startDate = new Date(this.selectedFilters?.startDate);
    } else { startDate = null;}

    if(this.selectedFilters?.endDate){
      endDate = new Date(this.selectedFilters?.endDate);
   } else { endDate = null;}
    this.historyObject.fromCreatedDate = startDate === null ? startDate: this.formatedDate(startDate, constantStrings.STARTDATE);
    if (this.selectedFilters.startDate && !this.selectedFilters.endDate) {
      this.historyObject.toCreatedDate = this.formatedDate(this.today, constantStrings.ENDDATE);
    } else {
      this.historyObject.toCreatedDate = endDate === null ? endDate: this.formatedDate(endDate, constantStrings.ENDDATE);
    }
    this.historyObject.page = 0;
    if (this.isPatientRecordLinkActive) {
      this.historyDetailsAPI(this.historyObject);
    }
    else {
      this.uploadService.batchUploadSubject.next(this.historyObject);
    }
    //Display filtered buttons in header
    if (this.selectedFilters?.startDate && this.selectedFilters?.endDate) {
      this.selectedStartDate = this.selectedFilters?.startDate
      this.startDateGradient = false;
    }
    if (this.selectedFilters?.endDate) {
      this.selectedEndDate = this.selectedFilters?.endDate
      this.startDateGradient = false;
      this.startEndGradient = false;
    }
    if (this.selectedFilters?.startDate && !this.selectedFilters.endDate) {
      this.selectedStartDate = this.selectedFilters?.startDate;
      this.selectedEndDate = this.today;
      this.startDateGradient = false;
    }
    if (!this.selectedFilters?.startDate && !this.selectedFilters?.endDate) {
      this.selectedStartDate = null;
      this.selectedEndDate = null;
    }
    if (this.selectedFilters?.uploader) {
      this.selectedUploader = [];
      this.newList = this.selectedFilters.uploader;
      for (let uploader of this.FilterModel.apiUploaderRes) {
        for (let id of this.newList)
          if (id == uploader.uploaderId) {
            if (!this.selectedUploader.includes(uploader.uploaderId)) {
              this.selectedUploader.push(uploader);
              this.startDateGradient = true;
              this.startEndGradient = true;
              this.uploaderGradient = false;
            }
          }
      }
    }
    if (this.selectedFilters?.patient) {
      this.selectedPatient = [];
      this.newList = this.selectedFilters.patient;
      for (let patient of this.FilterModel.apiPatientRes) {
        for (let id of this.newList)
          if (id == patient.patientName) {
            if (!this.selectedPatient.includes(patient.patientName)) {
              this.selectedPatient.push(patient);
              this.startDateGradient = true;
              this.startEndGradient = true;
              this.uploaderGradient = true;
              this.patientGradient = false;
            }
          }
      }
    }
    if (this.selectedFilters?.surgeon) {
      this.selectedSurgeon = [];
      this.newList = this.selectedFilters.surgeon;
      for (let surgeon of this.FilterModel.apiSurgeonRes) {
        for (let id of this.newList)
          if (id == surgeon.surgeonId) {
            if (!this.selectedSurgeon.includes(surgeon.surgeonId)) {
              this.selectedSurgeon.push(surgeon);
              this.startDateGradient = true;
              this.startEndGradient = true;
              this.uploaderGradient = true;
              this.patientGradient = true;
              this.surgeonGradient = false;
            }
          }
      }
    }
    if (this.selectedFilters?.site) {
      this.selectedSite = [];
      this.newList = this.selectedFilters.site;
      for (let site of this.FilterModel.apiSiteRes) {
        for (let id of this.newList)
          if (id == site.facilityId) {
            if (!this.selectedSite.includes(site.facilityId)) {
              this.selectedSite.push(site);
              this.startDateGradient = true;
              this.startEndGradient = true;
              this.uploaderGradient = true;
              this.patientGradient = true;
              this.surgeonGradient = true;
              this.siteGradient = false;
            }
          }
      }
    }
    if (this.selectedFilters?.status) {
      this.selectedStatus = [];
      this.newList = this.selectedFilters.status;
      for (let status of this.FilterModel.apiStatusRes) {
        for (let id of this.newList)
          if (id == status) {
            if (!this.selectedStatus.includes(status)) {
              this.selectedStatus.push(status);
              this.startDateGradient = true;
              this.startEndGradient = true;
              this.uploaderGradient = true;
              this.patientGradient = true;
              this.surgeonGradient = true;
              this.siteGradient = true;
              this.statusGradient = false;
            }
          }
      }
    }
    this.filterActiveFlag();
  }

  getFormatedDatefilter(date) {
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
  }

  //Function that deletes the filter items and update in the filter pop-up
  deleteFilter(item, id) {
    if (id == constantStrings.STARTDATE || id == constantStrings.ENDDATE) {
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      if (this.FilterModel) {
        if (id == constantStrings.STARTDATE) {
          this.FilterModel.onSelect(null, constantStrings.STARTDATE);
          this.displayFilterItems();
        } else {
          this.FilterModel.onSelect(null, constantStrings.ENDDATE);
          this.displayFilterItems();
        }
      }
    }
    if (id == constantStrings.UPLOADER) {
      for (let uploader of this.selectedUploader) {
        if (item == uploader) {
          this.selectedUploader.splice(this.selectedUploader.indexOf(uploader), 1);
        }
      }
      if (this.FilterModel) {
        this.FilterModel.onSelect(item.uploaderId, constantStrings.UPLOADER);
        this.displayFilterItems();
      }
    }
    if (id == constantStrings.PATIENT) {
      for (let patient of this.selectedPatient) {
        if (item == patient) {
          this.selectedPatient.splice(this.selectedPatient.indexOf(patient), 1);
        }
      }
      if (this.FilterModel) {
        this.FilterModel.onSelect(item.patientName, constantStrings.PATIENT);
        this.displayFilterItems();
      }
    }
    if (id == constantStrings.surgeonGroup) {
      for (let surgeon of this.selectedSurgeon) {
        if (item == surgeon) {
          this.selectedSurgeon.splice(this.selectedSurgeon.indexOf(surgeon), 1);
        }
      }
      if (this.FilterModel) {
        this.FilterModel.onSelect(item.surgeonId, constantStrings.surgeonGroup);
        this.displayFilterItems();
      }
    }
    if (id == constantStrings.SITE) {
      for (let site of this.selectedSite) {
        if (item == site) {
          this.selectedSite.splice(this.selectedSite.indexOf(site), 1);
        }
      }
      if (this.FilterModel) {
        this.FilterModel.onSelect(item.facilityId, constantStrings.SITE);
        this.displayFilterItems();
      }
    }
    if (id == constantStrings.STATUS && this.isPatientRecordLinkActive) {
      for (let status of this.selectedStatus) {
        if (item == status) {
          this.selectedStatus.splice(this.selectedStatus.indexOf(status), 1);
        }
      }
      if (item && item.status && (item.status.toLowerCase() == statusCodeString.UPLOADED)) {
        let index = this.historyObject.uploadStatus.indexOf(statusCode.UPLOADED);
        if (index >= 0) {
          this.historyObject.uploadStatus.splice(index, 1);
            this.historyDetailsAPI(this.historyObject);
        }
      }

      if (item && item.status && (item.status.toLowerCase() == statusCodeString.PROCESSING)) {
        let index = this.historyObject.uploadStatus.indexOf(statusCode.PROCESSING);
        if (index >= 0) {
          this.historyObject.uploadStatus.splice(index, 1);
            this.historyDetailsAPI(this.historyObject);
        }
      }

      if (item && item.status && (item.status.toLowerCase() == statusCodeString.COMPLETE)) {
        let index = this.historyObject.uploadStatus.indexOf(statusCode.COMPLETE);
        if (index >= 0) {
          this.historyObject.uploadStatus.splice(index, 1);
            this.historyDetailsAPI(this.historyObject);
        }
      }

      if (item && item.status && (item.status.toLowerCase() == statusCodeString.ERROR)) {
        let index = this.historyObject.uploadStatus.indexOf(statusCode.ERROR);
        if (index >= 0) {
          this.historyObject.uploadStatus.splice(index, 1);
            this.historyDetailsAPI(this.historyObject);
        }
      }

      if (item && item.status && (item.status.toLowerCase() == statusCodeString.UNPROCESSED)) {
        let index = this.historyObject.uploadStatus.indexOf(statusCode.UNPROCESSED);
        if (index >= 0) {
          this.historyObject.uploadStatus.splice(index, 1);
            this.historyDetailsAPI(this.historyObject);
        }
      }

      if (item && item.status && (item.status.toLowerCase() == statusCodeString.RECALLED)) {
        let index = this.historyObject.uploadStatus.indexOf(statusCode.RECALLED);
        if (index >= 0) {
          this.historyObject.uploadStatus.splice(index, 1);
            this.historyDetailsAPI(this.historyObject);
        }
      }

      if (item && item.status && (item.status.toLowerCase() == statusCodeString.GROUPED)) {
        let index = this.historyObject.uploadStatus.indexOf(statusCode.GROUPED);
        if (index >= 0) {
          this.historyObject.uploadStatus.splice(index, 1);
            this.historyDetailsAPI(this.historyObject);
        }
      }
      if (this.FilterModel) {
        this.FilterModel.onSelect(item, constantStrings.STATUS);
        this.displayFilterItems();
      }
    }
    else{
      for (let status of this.selectedStatus) {
        if (item == status) {
          this.selectedStatus.splice(this.selectedStatus.indexOf(status), 1);
        }
      }
      if (item && item.status && (item.status.toLowerCase() == statusCodeString.PENDING.toLowerCase())) {
        let index = this.historyObject.uploadStatus.indexOf(statusCode.UPLOADPENDING);
        if (index >= 0) {
          this.historyObject.uploadStatus.splice(index, 1);
        }
      }
      //Failed status
      if (item && item.status && (item.status.toLowerCase() == statusCodeString.FAILED.toLowerCase())) {
        let index = this.historyObject.uploadStatus.indexOf(statusCode.FAILED);
        if (index >= 0) {
          this.historyObject.uploadStatus.splice(index, 1);
         }
      }
      if (item && item.status && (item.status.toLowerCase() == statusCodeString.CORRUPTED.toLowerCase())) {
        let index = this.historyObject.uploadStatus.indexOf(statusCode.CORRUPTED);
        if (index >= 0) {
          this.historyObject.uploadStatus.splice(index, 1);
         }
      }
      if (item && item.status && (item.status.toLowerCase() == statusCodeString.PARTIALLY_CORRUPTED.toLowerCase())) {
        let index = this.historyObject.uploadStatus.indexOf(statusCode.PARTIALLY_CORRUPTED);
        if (index >= 0) {
          this.historyObject.uploadStatus.splice(index, 1);
         }
      }
      if(this.selectedStatus.length==0)
      this.historyObject.uploadStatus=[statusCode.UPLOADPENDING,statusCode.FAILED,statusCode.CORRUPTED,statusCode.PARTIALLY_CORRUPTED ];
      if (this.FilterModel) {
        this.FilterModel.onSelect(item, constantStrings.STATUS);
        this.displayFilterItems();
      }
      //this.uploadService.batchUploadSubject.next(this.historyObject);
    }
    this.filterActiveFlag();
  }
  /**
  * (description): method called display the filtered items
  * (parameter) : null
  * (memberof) : Component
  */
  filterActiveFlag() {
    if (this.selectedFilters?.uploader?.length > 0 || this.selectedFilters?.patient?.length > 0 || this.selectedFilters?.startDate
      || this.selectedFilters?.endDate || this.selectedFilters?.surgeon?.length > 0
      || this.selectedFilters?.site?.length > 0 || this.selectedFilters?.status?.length > 0) {
      this.filterActive = true;
    } else {
      this.filterActive = false;
    }
  }
  /**
  * (description): method called on click of any row in table to enable view details button
  * (parameter) : null
  * (memberof) : Component
  */
  isDisable() {
    this.isDisabled = true;
  }
  /**
  * (description): method to log audit actions
  * (parameter) : action
  * (memberof) : Component
  */
  auditLogActivity(str) {
    if (str === constantStrings.SEARCH) {
      this.auditText = this.auditAction.UserActions.searchLog;
      this.auditId = 'History page';
    } else {
      this.auditText = this.auditAction.UserActions.filterLog;
      this.auditId = null;
    }
    let actionOutcome = {
      "userFacilityId": this.userInfo.userFacilityId,
      "userName": this.userInfo.userName,
      "action": this.auditId ? this.auditText + '' + this.auditId : this.auditText,
      "eventOutCome": this.historyData ? 1 : 0
    }
    this.uploadService.auditLogActivity(actionOutcome).subscribe({
      next: result => {
        this.actionActivityDetails = result;
      }, error: err => {
        this.errUserDetails = err;
      }
    });
  }

  navigateTab(event) {
    if (this.filterActive) {
      this.filterActive = false;
    }
    this.isdayChecked=false;
    this.filterFlag=false;
    this.searchText='';
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedUploader = [];
    this.selectedPatient = [];
    this.selectedSurgeon = [];
    this.selectedSite = [];
    this.selectedStatus = [];
   if (event === this.patientRecord) {
      this.isPatientRecordLinkActive = true;
      this.isBatchRecordLinkActive = false;
      this.historyObject.fromCreatedDate = null;
      this.historyObject.toCreatedDate = null;
      this.historyObject.uploadId = [];
      this.historyObject.uploaderId = [];
      this.historyObject.surgeonId = [];
      this.historyObject.targetFacilityId = [];
      this.historyObject.uploadStatus = [];
      this.historyObject.linkedFacilityId=this.userInfo.userFacilityId;
      this.historyObject.searchText= '';
      this.historyObject.page= 0;
      this.historyObject.limit= this.pagelimit;
      this.historyObject.column= constantStrings.createdDate;
      this.historyDetailsAPI(this.historyObject);
      this.viewDetailsFlag=false;
    }
    else {
      this.isPatientRecordLinkActive = false;
      this.isBatchRecordLinkActive = true;
      this.historyObject.searchText='';
    }
    
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { INITIAL_MODAL_STATE, Modal } from 'src/app/shared/models/dialog.model';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss']
})
export class WarningPopupComponent implements OnInit {

  @Input() modal: Modal = INITIAL_MODAL_STATE;
  constructor() { }

  ngOnInit(): void {
  }

}

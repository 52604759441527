import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import * as AWS from 'aws-sdk';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CloudService } from '../../shared/services/cloud.service';
import { ApiHttpService } from 'src/app/api-http.service';
import { ModulePermissions } from '../models/permissions.model';
import { environmentURL, errorResponse, settingsResponse } from '../models/user.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadService } from 'src/app/uploadfiles/upload.service';
import { SharedStateService } from './shared-state.service';
import FingerprintJS from '@fingerprintjs/fingerprintjs/dist/fp.esm';
import { ROLE } from '../helpers/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private session: any;
  private modulePermission: ModulePermissions[];
  private modulePermissionModel: ModulePermissions;
  private storeModulePermission: ModulePermissions[]
  public tokenExpired: any;
  public cognitoIdToken: any;
  public authenticated: any;
  public decodedIdToken: any;
  public fileuploadURL: boolean;
  getItemRes: any;
  userInfo: any;
  signOutResponse: any;
  errUserDetails: errorResponse;
  actionActivityDetails: settingsResponse;
  auditAction: any;
  baseState: string;
  baseEnv: environmentURL;
  sqsErrorMessage: errorResponse;
  messageId: string;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private cloudService: CloudService,
    private spinnerService: NgxSpinnerService,
    private uploadService: UploadService,
    private sharedState: SharedStateService,
    private http: ApiHttpService
  ) {
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    //Recieving loggedIn user details from local storage 
    if (this.getItem('userDetails')) {
      this.userInfo = this.getItem('userDetails');
    }
    const idToken = this.getCognitoAccessTokenFromStorage();
    const decodedValue = this.decode(idToken);
    this.baseState = decodedValue ? decodedValue['cognito:groups'][0] : '';
    if(this.baseState.toLowerCase() == ROLE.SNN_HIL_MANAGER.toLowerCase()){
        this.baseEnv = environment.snnClp
    } else {
        this.baseEnv = environment.clp
    }
  }


  /**
   * (description): Retrieves the item's string representation from the local storage
   * (parameter) : string
   * (returns) : string
   * (memberof) : AuthService
   */
  public getItem(key: string): any {
    this.getItemRes = sessionStorage.getItem(key);
    return JSON.parse(sessionStorage.getItem(key));
  }

  /**
 * (description): Removes the item's string representation from the local storage
 * (parameter) : string
 * (returns) : string
 * (memberof) : AuthService
 */
  public removeItem(key: string): any {
    sessionStorage.removeItem(key);
  }

  async getVisitorId(){
    return new Promise<string>(async (resolve, reject) => {
      try {
        const fpPromise = FingerprintJS.load();
        const fp = await fpPromise;
        const result = await fp.get();
        sessionStorage.setItem('visitorId', result.visitorId) 
        resolve(result.visitorId)
      }
      catch(e){
        reject(e);
      } 
    });
  }

  /*
   * @description Sets the item in the local storage
   * @param {string} key
   * @param {*} value
   * @memberof AuthService
   */
  public setItem(key: string, value: any): void {
    if (typeof value === 'string') {
      sessionStorage.setItem(key, value);
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  /*
   * @description Decode the JWT token
   *
   * @returns
   * @memberof AuthService
   */
  decode(idToken?: string): any {
    if (!!idToken && idToken !== undefined) {
      this.decodedIdToken = jwt_decode(idToken);
    }
    return this.decodedIdToken;
  }


  public getModulePermission(): ModulePermissions[] {
    let snPermission;
    if (this.modulePermission) {
      snPermission = this.modulePermission;
      this.cloudService.getSnPermission = snPermission;
    } else if (this.cloudService.getSnPermission !== undefined) {
      snPermission = this.cloudService.getSnPermission;
      this.cloudService.getSnPermission = snPermission;
    }
    this.modulePermission = snPermission;
    return this.modulePermission;
  }
  setModulePermission(modulePermission: ModulePermissions[]): void {
    this.cloudService.getSnPermission = modulePermission;
    this.modulePermission = modulePermission;
  }

  /**
   * (description): Refresh the token
   * (memberof) : AuthService
   */
  public refreshToken(requestUrl = ''): any {
    let accessToken = '';
    return new Promise((resolve, reject) => {
      this.cloudService.currentSession()
        .then((session: any) => {
          this.session = session;
          this.fileuploadURL = requestUrl.includes('imageupload/fileupload');
          if (this.fileuploadURL) {
            return resolve(session.getAccessToken().getJwtToken());
          }
          const idTokenExpire = session.getIdToken().getExpiration();
          const refreshToken = session.getRefreshToken();
          const currentTimeSeconds = Math.round(+new Date() / 1000);
          if (idTokenExpire < currentTimeSeconds) {
            // in case of expired use the existing token when user closes browser and reopens
            accessToken = session.getAccessToken().getJwtToken();
            resolve(accessToken);
          } else {
            this.cloudService.currentAuthenticatedUser()
              .then((res: any) => {
                res.refreshSession(refreshToken, (err: any, data: any) => {
                  if (err) {
                    //this.router.navigate(['/uploadfiles/uploadError']);
                    reject(err);
                  } else {
                    accessToken = data.getAccessToken().getJwtToken();
                    resolve(accessToken);
                  }
                });
              });
            accessToken = session.getAccessToken().getJwtToken();
            resolve(accessToken);
          }
        })
        .catch(() => {
          // No logged-in user: don't set auth header
          resolve(accessToken);
        });
    });
  }




  /**
   * (description): check for expiration and if token is still existing or not
   * (returns) : boolean
   * (memberof) : AuthService
   */
  isAuthenticated(): boolean {
    this.authenticated = false;
    this.authenticated = !!this.getCognitoIdTokenFromStorage() && !this.isTokenExpired();
    return this.authenticated;
  }

  /**
   * (description): simulate jwt token is valid
   * (returns) : boolean
   * (memberof) : AuthService
   */
  isTokenExpired(): boolean {
    this.tokenExpired = false;
    return false;
  }



  /**
   * (description): Method returns the ID token
   * (returns) : string
   * (memberof) : AuthService
   */
  getCognitoIdTokenFromStorage(): string {
    let cognitoIdToken = undefined;
    for (const key in sessionStorage) {
      if (key.includes('clpSession')) {
        const session = JSON.parse(sessionStorage.getItem('clpSession')); //by GP
        cognitoIdToken = session.idToken;
      }
    }
    return cognitoIdToken;
  }


  /**
   * (description): Method returns the Access token
   * (returns) : string
   * (memberof) : AuthService
   */
  getCognitoAccessTokenFromStorage(): string {
    let cognitoAccessToken: any;
    if (!!this.session) {
      cognitoAccessToken = this.session.getAccessToken().getJwtToken();
    } else {
      for (const key in sessionStorage) {
        if (key.includes('clpSession')) {
          const session = JSON.parse(sessionStorage.getItem('clpSession')); //by GP
          cognitoAccessToken = session.accessToken;        }
      }
    }
    return cognitoAccessToken;
  }


  /**
   * (description): Method to signout current user.
   * (returns) : string
   * (memberof) : AuthService
   */
  public signOut(): void {
    const key = 'device_key';
    const decodedAccesssToken = this.decode(this.getCognitoAccessTokenFromStorage());
    const deviceKey = decodedAccesssToken ? decodedAccesssToken[key] : '';
    const params = {
      AccessToken: this.getCognitoAccessTokenFromStorage(),
      DeviceKey: deviceKey,
    };
    const apiVersions = {
      cognitoidentityserviceprovider: environment.apiVersionCognito,
      // other service API versions
    };
    const userPoolConfig: string = this.baseEnv.userPoolAwsLogin;
    const loginObj: any = {};
    loginObj[userPoolConfig] = this.getCognitoIdTokenFromStorage();
    const awsCredentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: this.baseEnv.identityPoolId,
      Logins: loginObj
    });
    const region = environment.region;
    const config = new AWS.Config({
      region,
      apiVersions,
      credentials: awsCredentials
    });
    AWS.config.update(config);
    const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
    cognitoIdentityServiceProvider.forgetDevice(
      params, (forgetDeviceError, forgetDeviceResult) => {
        this.authServiceSignout();
      });
  }


  /**
   * (description): This method is called internally for signout
   * (memberof) : AuthService
   */
  public authServiceSignout(): void {
    this.cloudService.amplifySignOut()
      .subscribe(
        (response) => {
          this.logout();
          this.signOutResponse = response;
        },
        (error) => {
          this.logout();
        }
      );
  }

  /**
   * (description): This method is used to clear sessionStorage
   * (memberof) : AuthService
   */
  clear(): void {
    this.session = undefined;
    sessionStorage.clear();
    this.cognitoIdToken = null;
  }


  /**
   * (description): This method is used to clear local storage and also the route to login
   * (memberof) : AuthService
   */
  logout(): void {
    this.clearUserSessionUser(); // for okta  

    this.storageService.clearAll();
    this.clear();
    this.spinnerService.hide();
    const idToken = this.getCognitoAccessTokenFromStorage();
    const decodedValue = this.decode(idToken);
    this.baseState = decodedValue ? decodedValue['cognito:groups'][0] : '';
    if(this.baseState.toLowerCase() == ROLE.SNN_HIL_MANAGER.toLowerCase() || this.baseState.toLowerCase() == ROLE.SNN_HIL_REVIEWER.toLowerCase()
      || this.baseState.toLowerCase() == ROLE.SNN_CLOUD_SUPPORT.toLowerCase()){
      this.baseEnv = environment.snnClp
    } else {
        this.baseEnv = environment.clp
    }
    window.open(this.baseEnv.cloudFrontCLPSignOutUrl, "_self");

  }

  // /**
  //  * @description trigger logout Okta
  //  * @returns navigates to confirm regiter page
  //  * @memberof RegisterComponent
  //  */
  // clearUserSessionUser(): any {
  //   this.userService.clearUserSessionUser();
  // }

      /**
   * @description trigger logout Okta
   * @returns navigates to confirm regiter page
   * @memberof RegisterComponent
   */
  clearUserSessionUser(): any {
        let idToken = this.getCognitoIdTokenFromStorage();
        if(idToken){
        let decodedValue = this.decode(idToken);
        var userName = decodedValue['cognito:username'];
        userName = userName.substring(5);
    
        this.http.get(environment.baseUrl+'/clp/userClearSession?username='+userName).subscribe(
           (response) => {
            },
            (error) => {
               if (error !== null) {
                   error = error.message;
               }
           }
        );
      }
     }
    
  public async setCognitoConfigObject(): Promise<any> {
    try {
      const userPoolConfig: string = this.baseEnv.userPoolAwsLogin;
      const loginObj: any = {};
      loginObj[userPoolConfig] = this.getCognitoIdTokenFromStorage();
      const awsCredentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.baseEnv.identityPoolId,
        Logins: loginObj
      });
      awsCredentials.clearCachedId();
      awsCredentials.refresh((err)=>{
      })
      AWS.config.update({
        credentials: awsCredentials,
        region: environment.region,
      });
      return AWS;
    } catch (err) {
      return err;
    }

  }


  async deleteCognitoUser(userName, callback): Promise<any> {
    const value = await this.setCognitoConfigObject();

    const deleteParams = {
      UserPoolId: this.baseEnv.userPoolId,
      Username: userName,
    };

    const cognito = new value.CognitoIdentityServiceProvider();
    cognito.adminDeleteUser(deleteParams, (err, data) => {
      if (err) {
        return callback(err, null);
      }
      else {
        return callback(null, data);
      }
    });

  }

  async removeAdminFromAdminGroup(userName, callback): Promise<any> {

    const value = await this.setCognitoConfigObject();

    const params = {
      GroupName: 'ic-admin',
      UserPoolId: this.baseEnv.userPoolId,
      Username: userName
    };

    const cognito = new value.CognitoIdentityServiceProvider();
    cognito.adminRemoveUserFromGroup(params, (err, data) => {
      if (err) {
        return callback(err, null);
      }
      else {
        return callback(null, data);
      }
    });
  }

  async addAdminToUserGroup(userName, callback): Promise<any> {

    const value = await this.setCognitoConfigObject();
    const params = {
      GroupName: 'ic-user',
      UserPoolId: this.baseEnv.userPoolId,
      Username: userName
    };

    const cognito = new value.CognitoIdentityServiceProvider();
    cognito.adminAddUserToGroup(params, (err, data) => {
      if (err) {
        return callback(err, null);
      }
      else {
        return callback(null, data);
      }
    });

  }



  async removeUserFromGroup(userName, callback): Promise<any> {

    const value = await this.setCognitoConfigObject();

    const params = {
      GroupName: 'ic-user',
      UserPoolId: this.baseEnv.userPoolId,
      Username: userName
    };

    const cognito = new value.CognitoIdentityServiceProvider();
    cognito.adminRemoveUserFromGroup(params, (err, data) => {
      if (err) {
        return callback(err, null);
      }
      else {
        return callback(null, data);
      }
    });
  }


  async addUserToAdminGroup(userName, callback): Promise<any> {

    const value = await this.setCognitoConfigObject();
    const params = {
      GroupName: 'ic-admin',
      UserPoolId: this.baseEnv.userPoolId,
      Username: userName
    };

    const cognito = new value.CognitoIdentityServiceProvider();
    cognito.adminAddUserToGroup(params, (err, data) => {
      if (err) {
        return callback(err, null);
      }
      else {
        return callback(null, data);
      }
    });

  }
  /**
   * (description): Navigate application to CLP product screen
   * (memberof) : component
  **/
  exitToApps(){
    window.open( this.baseEnv.cloudFrontCLPExitAppUrl, "_self");
  }

  /**
   * (description): To call audit log activity function
   * (memberof) : component
  **/
   auditLogActivity(){
    let action = {
      "userFacilityId" : this.userInfo.userFacilityId,
      "userName" : this.userInfo.userName,
      "action" : this.signOutResponse ? this.auditAction.UserActions.logoutSuccess : this.auditAction.UserActions.logoutUnsuccess,
      "eventOutCome" : this.signOutResponse ? 1 : 0
    }
    this.uploadService.auditLogActivity(action).subscribe({
      next: result => {
        this.actionActivityDetails = result;
      }, error: err => {
        this.errUserDetails = err;
      }
    });
  }

  /**
   * (description): This method is used to get the SSM Parameters
   * (memberof) : AuthService
   */
    getParameters(key, callback): any{
      const idToken = this.getCognitoAccessTokenFromStorage();
      const decodedValue = this.decode(idToken);
      this.baseState = decodedValue ? decodedValue['cognito:groups'][0] : '';
      if(this.baseState.toLowerCase() == ROLE.SNN_HIL_MANAGER.toLowerCase()){
          this.baseEnv = environment.snnClp
      } else {
          this.baseEnv = environment.clp
      }
      const userPoolConfig: string = this.baseEnv.userPoolAwsLogin;
      const loginObj: any = {};
      loginObj[userPoolConfig] = this.getCognitoIdTokenFromStorage();
      const awsCredentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.baseEnv.identityPoolId,
        Logins: loginObj
      });
      //Clears the cached Cognito ID which is asscoiated with PoolID
      awsCredentials.clearCachedId();
      const region = environment.region;
      const config = new AWS.Config({
        region,
        credentials: awsCredentials
      });
      AWS.config.update(config);
      const ssm = new AWS.SSM();
      const params = {
        Name: key,
        WithDecryption: true
      };
      ssm.getParameter(params, (err, data) => {
        if (err) {
          callback(err, null);
      } else {
          callback(null, data);
      }
      });
    }
  /**
   * (description): To get the ssm parameters
   * (memberof) : AuthService
   */
  public getSSMValue(key): any {   
    let ssmValObj = {};
    this.getParameters(key, (err, result) => {
      if (err) {
      return err;
      }
      else {
        if(result){
          ssmValObj = {
            ssmKey : key,
            ssmVal : result.Parameter.Value
          }
          this.sharedState.ssmIdleTimeVal(ssmValObj);          
        }    
      }
    })
  }

  /** 
   * (description): To get the ssm parameters
   * (memberof) : AuthService
   */ 
  public getSSMTagValue(key): any {   
    let ssmValObj = {};
    this.getParameters(key, (err, result) => {
      if (err) {
      return err;
      }
      else {        
        if(result){
          ssmValObj = {
            ssmKey : key,
            ssmVal : result.Parameter.Value
          }
          this.sharedState.ssmIdleTagVal(ssmValObj);          
        }    
      }
    })
  }
  /**
   * (description): To send message to SQS queue
   * (memberof) : AuthService
   */
   public getSQSValue(batchId, sqsQueueUrl): any {   
    var AWSSqs = require('aws-sdk');
    // Set the region 
    AWSSqs.config.update({region: environment.region});
    // Create an SQS service object
    var sqs = new AWS.SQS({apiVersion: environment.apiVersionSqs});
    var params = {
      DelaySeconds: 180,
      MessageBody: batchId,
      QueueUrl: sqsQueueUrl
    };
    sqs.sendMessage(params, function(err, data) {
      if (err) {
        this.sqsErrorMessage = err;
      } else {
        this.messageId = data.MessageId;
      }
    });
  }
}




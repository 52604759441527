export const constantStrings = {
    defaultCountryName: 'United States',
    defaultLanguage: 'en',
    setTimeOut: 5000,
    surgeonGroup: 'surgeon',
    userName: 'userName',
    NEXT_UPLOAD_ID: 'nextUpload', //Constant to store Next Upload path Id
    NEW_UPLOAD_ID: 'newUpload',//Constant to store New Upload path Id
    DATE_FORMAT: 'MMM DD,YYYY', //Constant for date format
    DATE_FORMAT_YEAR: 'YYYY', //constant for date format year
    DATE_FORMAT_MONTH: 'MMMM', //constant for date format month
    SITE: 'site',
    APPLICATION: 'application',
    STATUS: 'status',
    setExpiryTime: 900,
    cardsCount: 9,
    SUCCESS: 'success',
    UNSUCCESS: 'unsuccess',
    DATE: 'date',
    STARTDATE: 'startDate',
    ENDDATE: 'endDate',
    FACILITYIMAGING: 'Imaging Center Admin',
    FACILITYPROVIDER: 'Hospital Admin',
    ACTIVESTATUS: 'Active',
    INACTIVESTATUS: 'Inactive',
    NASTATUS: 'NA',
    PROCESSING: 'Uploading',
    FAILED: 'Failed',
    UPLOADED: 'UPLOADED',
    ERROR: 'ERROR',
    UPLOADFILE: 'uploadFile',
    UPLOADSTATUS: 'uploadStatus',
    ALLSKIPPED: 'allFilesSkipped',
    TECHNICALERROR: 'technicalError',
    FILESIZERR: 'fileSizeErr',
    APIHANDLEERROR: 'There was technical issue. Please try again later.',
    PATIENT: 'patient',
    UPLOADER: 'uploader',
    CANCELLED: "Cancelled",
    SEARCH: 'search',
    FILTER: 'filter',
    VIEW: 'view',
    LINK: 'link',
    DELINK: 'delink',
    ACTIVATESITE: 'activatesite',
    DEACTIVATESITE: 'deactivatesite',
    AUDITEXPORT: 'auditLogExport',
    TOGGLE: 'toggle',
    eventDate: 'EventDate',
    UserID: 'UserID',
    Action: 'Action',
    loginSuccess: 'LoginSuccess',
    createdDate: 'createdDate',
    hideRecall : 'hide_recall_btn',
    reUpload: 'reUpload',
    nextUpload: 'nextUpload',
    newUpload: 'newUpload',
    bytes: 'bytes',
    kb: 'KB',
    mb: 'MB',
    gb: 'GB',
    imageRender: 'data:image/png;base64,',
    auditDownload: 'auditDownload',
    auditReady: 'auditReady',
    logDownload: 'logDownload',
    snnPortal: 'SNN_PORTAL',
    otherPortal: 'OTHER_PORTAL',
    displayDOB:'MM/DD/YYYY',
    firstName:'fname',
    lastName:'lname',
    studyDesc:'studydesc',
    dateOfBirth:'dob',
    dobFormat:'MM/dd/YYYY',
    inProgress:'inprogress',
    patientRecord:'patientrecord',
    batchRecord:'batchrecord',
    historyPage:'historypage',
    batchPage:'batchpage',
    uploadIncomplete:'upload_incomplete',
    uploadTimeLimit : 20,
    INITIATED:'Initiated',
    hostedUISNN:'snnClp',
    hostedUICLP:'Clp',
    uploadId:'uploadId',
    surgeon: 'surgeon',
    batchId: 'batchId'
 }

export const statusCode = {
    UPLOADED: 1,
    PROCESSING: 2,
    COMPLETE: 3,
    ERROR: 4,
    UNPROCESSED: 5,
    RECALLED: 6,
    GROUPED: 7,
    PageLimit: 25,
    INACTIVE: 1,
    ACTIVE: 2,
    UPLOADPENDING:22,
    FAILED:21,
    PARTIALLY_CORRUPTED:30,
    CORRUPTED:31
}

export const statusCodeString = {
    UPLOADED: 'uploaded',
    PROCESSING: 'processing',
    COMPLETE: 'complete',
    ERROR: 'error',
    UNPROCESSED: 'unprocessed',
    RECALLED: 'recalled',
    GROUPED: 'grouped',
    ACKNOWLEDGED: 'acknowledged',
    PENDING:'Pending',
    FAILED:'Failed',
    CORRUPTED: 'Corrupted',
    PARTIALLY_CORRUPTED: 'Partially Corrupted'
}

//Side menu access roles names
export const ROLE = {
    IC_ADMIN: 'ic-admin',
    IC_USER: 'ic-user',
    NURSE: 'nurse',
    SURGEON: 'surgeon',
    H_ADMIN: 'hospital_admin',
    SNN_HIL_MANAGER: 'snn-hil-manager',
    SNN_CLOUD_SUPPORT: 'admin',
    STAFF: 'staff',
    SNN_HIL_REVIEWER: 'snn-hil-reviewer'
}

//Side menu access positions names
export const POSITION = {
    IC_ADMIN: 'Imaging Center Admin',
    IC_USER: 'Imaging Center Technician',
    NURSE: 'Nurse',
    SURGEON: 'Surgeon',
    H_ADMIN: 'Hospital Admin',
    SNN_HIL_MANAGER: 'SNN HIL Manager',
    SNN_CLOUD_SUPPORT: 'SNN Cloud Support',
    STAFF: 'Support Staff'
}

//Facility Linking sort Id's
export const linkingNames = {
    FACILITYNAME: 'facilityName',
    ADDRESS: 'address',
    CITY: 'city',
    FACILITYCONTACTLEAD: 'facilityContactLead',
    FACILITYPHONE: 'facilityPhone'
}

export const APINames = {
    HISTORY : "History",
    FACILITY : "Facility",
    SURGEON : "Surgeon",
    LOGIN : "Login",
    SKIPPEDFILE: "Skipped File",
    FILEUPLOAD: "File Upload",
    RECALL: "Recall",
    BATCH: "Batch",
    GROUPBY: "Group By Patient",
    FILTER: "Filter",
    SETTINGS: "Settings",
    IMAGING: "Facility Linking",
    HISTORYDETAILS: "History Details",
    CREATELINK: "Create Link",
    REMOVELINK: "Remove Link",
    AUDITLOG: "Audit Log",
    DASHBOARD: "Dashboard",
    ACKNOWLEDGE: "Acknowledge",
    ACTIVATION: "Activation Status",
    EXPORT: "Export Log",
    EXPORTALL: "Export All Log",
    APPLICATION: "Application",
    FETCH: "Fetch Audit Log",
    IMAGINGCENTER: "Imaging Center",
    UPLOADSTATUS:"Upload Status",
    BATCHIDDETAILS:"BatchId Details"
}
import { Injectable } from '@angular/core';
import {
    HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpClient
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ROLE } from '../helpers/constants';
import { environmentURL } from '../models/user.model';
import { AuthService } from './auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private unauthorized = 401;
    private responseMessage: any;
    private responseErrorCode: any;
    private errorCodeLocal: any;
    private tokenMessage: any;
    session: any;
    decodedIdToken: any;
    errMsg: string;
    baseState: string;
    baseEnv: environmentURL;
    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
        private authService: AuthService
        ) {
          const idToken = this.getCognitoAccessTokenFromStorage();
          const decodedValue = this.decode(idToken);
          this.baseState = decodedValue ? decodedValue['cognito:groups'][0] : '';
          if(this.baseState.toLowerCase() == ROLE.SNN_HIL_MANAGER.toLowerCase()){
              this.baseEnv = environment.snnClp
          } else {
              this.baseEnv = environment.clp
          }
    }

    getCognitoAccessTokenFromStorage(): string {
        let cognitoAccessToken: any;
        if (!!this.session) {
          cognitoAccessToken = this.session.getAccessToken().getJwtToken();
        } else {
          for (const key in sessionStorage) {
            if (key.includes('clpSession')) {
              const session = JSON.parse(sessionStorage.getItem('clpSession'));
              cognitoAccessToken = session.accessToken;
            }
          }
        }
        return cognitoAccessToken;
      }

      /**
     * (description): decode idtoken
     * (parameter) : none
     * (returns) : callback
     * (memberof) : JwtInterceptor
     */
      decode(idToken?: string): any {
        if (!!idToken && idToken !== undefined) {
          this.decodedIdToken = jwt_decode(idToken);
        }
        return this.decodedIdToken;
      }

       /**
     * (description): refreshtoken
     * (parameter) : none
     * (returns) : callback
     * (memberof) : JwtInterceptor
     */
    public async refreshToken(): Promise<any> {
        let accessToken = '';
        
        return await new Promise((resolve, reject) => {
          const accessTokenFromStorage = this.getCognitoAccessTokenFromStorage();
          const decodedToken = this.decode(accessTokenFromStorage);
          const accessTokenExpire = decodedToken ? decodedToken.exp : '';
          let refreshToken = undefined;
          const expired = Date.now() >= accessTokenExpire * 1000;
          if (!expired && accessTokenFromStorage) {
            resolve(accessTokenFromStorage);
          } else {
            const session = JSON.parse(sessionStorage.getItem('clpSession'));
            if(!session){
                resolve('');
            }else{
                this.refreshTokenCode(session.refresh_token).then((data)=>{
                    session.accessToken = data.access_token;
                    session.idToken = data.id_token;
                    sessionStorage.setItem('clpSession', JSON.stringify(session));
                    resolve(data.access_token);
                }).catch((error)=>{
                    this.handleError(error);
                });
            }
          }
        });
      }


       /**
     * (description): refreshtoken
     * (parameter) : code
     * (returns) : callback
     * (memberof) : JwtInterceptor
     */
       async refreshTokenCode(code): Promise<any> {
        const test = `grant_type=refresh_token&client_id=${this.baseEnv.userPoolWebClientId}&refresh_token=${code}`;
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return await this.http.post(this.baseEnv.hostedUIUrl + 'token', test, {headers}).pipe(catchError(this.handleError)).toPromise();
  
      }

       /**
     * (description): S3 Get File Functionality
     * (parameter) : file
     * (returns) : ERROR
     * (memberof) : JwtInterceptor
     */
       private handleError(error: HttpErrorResponse): any {
        if (error.error instanceof ErrorEvent) {
          this.errMsg = 'An error occurred:' + error.error.message;
        } else {
          const statusCode = 400;
          if (error.status === statusCode) {
            this.errMsg = error.error.errorMessage;
          } else {
            this.errMsg = 'dada'
          }
        }
        return throwError(this.errMsg);
      }

      getCognitoIdTokenFromStorage(): string {
        let cognitoIdToken = undefined;
        for (const key in sessionStorage) {
          if (key.includes('clpSession')) {
            const session = JSON.parse(sessionStorage.getItem('clpSession'));
            cognitoIdToken = session.idToken;
          }
        }
        return cognitoIdToken;
      }

      clearUserSessionUser(): any {
        let idToken = this.getCognitoIdTokenFromStorage();
        if(idToken){
          let decodedValue = this.decode(idToken);
          var userName = decodedValue['cognito:username'];
          userName = userName.substring(5);
      
          this.http.get(environment.baseUrl+'/clp/userClearSession?username='+userName).subscribe(
            (response) => {
              },
              (error) => {
                if (error !== null) {
                    error = error.message;
                }
            }
          );
        }
      }

      clear(): void {
        this.session = undefined;
        sessionStorage.clear();
      }
    
    /**
     * This method is to intercept the HTTP request to set Authorization token
     *
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     * @memberof JwtInterceptor
     */

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let header: any = ' ';
        const auditLogActor: any = '';
        let urlAdd: any = '';
        
        this.translateService.get(['errorCode'])
        .subscribe((data) => {
            this.errorCodeLocal = data.errorCode;
        });
        let userAccessToken: any = '';
        if(request.url === this.baseEnv.hostedUIUrl + 'token'){
            return next.handle(request)
                    .pipe(
                        tap(event => {
                            if (event instanceof HttpResponse) {
                                /* Intentional Empty Block */
                            }
                        }, (error: HttpErrorResponse) => {
                            if (error instanceof HttpErrorResponse) {
                                this.responseMessage = error.error ? error.error.message : '';
                                this.responseErrorCode = error.error ? error.error.errorCode : '';
                                if (this.errorCodeLocal.hasOwnProperty(error.error.errorCode)) {
                                    error.error.errorMessage = this.errorCodeLocal[error.error.errorCode];
                                }
                                 else if (error.status === this.unauthorized || error.status === 0) {
                                    this.authService.signOut();                            
                                }
                            }
                        })
                    );
        }
        return from(this.refreshToken()).pipe(
            switchMap((tokenResult) => {
                userAccessToken = tokenResult;
                if (!!userAccessToken) {
                    const clpSessionToken = sessionStorage.getItem('surgeonId');
                    const surgeonId = clpSessionToken || '';
                    if (!!surgeonId) {
                        header = {
                            setHeaders: {
                                Authorization: `${userAccessToken}`,
                                surgeonid: `${surgeonId}`
                            }
                        };
                    } else {
                        header = {
                            setHeaders: {
                                Authorization: `${userAccessToken}`,
    
                            }
                        };
                    }
                    request = request.clone(header);

                    // code to stop IE from caching the requests
                    urlAdd = 'buster=' + new Date().getTime();
                    if (request.url.includes('?')) {
                        urlAdd = '&' + urlAdd;
                    } else {
                        urlAdd = '?' + urlAdd;
                    }
                    const newUrl = { url: request.url + urlAdd };
                    request = request.clone(newUrl);
                }
                return next.handle(request)
                    .pipe(
                        tap(event => {
                            if (event instanceof HttpResponse) {
                                /* Intentional Empty Block */
                            }
                        }, (error: HttpErrorResponse) => {
                            if (error instanceof HttpErrorResponse) {
                                this.responseMessage = error.error ? error.error.message : '';
                                this.responseErrorCode = error.error ? error.error.errorCode : '';
                                if (this.errorCodeLocal.hasOwnProperty(error.error.errorCode)) {
                                    error.error.errorMessage = this.errorCodeLocal[error.error.errorCode];
                                }
                                 else if (error.status === this.unauthorized || error.status === 0) {
                                  this.logoutCallPer();  
                                  // this.authService.signOut();
                                }
                            }
                        })
                    );
            }));
    }

    logoutCallPer(){
      this.clearUserSessionUser();
      sessionStorage.clear();
      this.clear();
      const idToken = this.getCognitoAccessTokenFromStorage();
      const decodedValue = this.decode(idToken);
      this.baseState = decodedValue ? decodedValue['cognito:groups'][0] : '';
      if(this.baseState.toLowerCase() == ROLE.SNN_HIL_MANAGER.toLowerCase() || this.baseState.toLowerCase() == ROLE.SNN_HIL_REVIEWER.toLowerCase()
        || this.baseState.toLowerCase() == ROLE.SNN_CLOUD_SUPPORT.toLowerCase()){
        this.baseEnv = environment.snnClp
      } else {
          this.baseEnv = environment.clp
      }
      window.open(this.baseEnv.cloudFrontCLPSignOutUrl, "_self"); 
    }
}

import { SelectionModel } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../shared/services/auth.service';
import { SharedStateService } from '../shared/services/shared-state.service';
import { ManageFacilityService } from './manage-facility.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantStrings, statusCode } from '../shared/helpers/constants';
import { errorResponse, FacilityRecord } from '../shared/models/user.model';
export interface manageOrganizations {
  name: string;
  facilityType: string;
  cityState: string;
  status: string;
}
export interface facilityDetail {
  name: string;
  cityState: string;
  phoneNo: string;
}

@Component({
  selector: 'app-managefacilities',
  templateUrl: './managefacilities.component.html',
  styleUrls: ['./managefacilities.component.scss'],
})
export class ManagefacilitiesComponent implements OnInit {

  displayedColumns: string[] = ['name', 'facilityType', 'cityState', 'status'];

  displayedColumnsTwo: string[] = [
    'icon',
    'centerName',
    'cityState',
    'phoneNo',
  ];

  dataSource = new MatTableDataSource<manageOrganizations>();
  dataSourceTwo = new MatTableDataSource<facilityDetail>();

  selFacilityId: number;
  itemSize = 50;
  isSurgeonTrue;
  rowIndex: number;
  showUnLicensedText: boolean = false;
  resizeWindow: boolean = false;
  errorInGetFacilities: errorResponse;
  facilitiesRes: any;
  facilityType: any = 0;
  orgDetails: any = [];
  selection: SelectionModel<manageOrganizations>;
  selectionTwo = new SelectionModel<facilityDetail>();
  isUnion: any = 1;
  facilityListRes: any;
  errorInfacilityList: errorResponse;
  searchText: string = '';
  searchTextOrg: string = '';
  userInfo: any;
  sourceFacilityId: any;
  targetFacilityId: any;
  errInpostFacilityLink: errorResponse;
  postFacilityLinkRes: any;
  errInputFacilityLink: errorResponse;
  selFacilityStatus: any;
  activateSite: boolean = false;
  deActivateSite: boolean = false;
  removeLink: boolean = false;
  createLink: boolean = false;
  facilityListResLength: any = [];
  facilityResLength: number = 0;
  selectedRowIndex: any;
  selectedImgRowIndex: any;
  selImgFacilityId: any;
  linkClickFlag: boolean = false;
  page: any = 1;
  pageOne: any = 0;
  pagelimit: any;
  storeFacilityLinkData: any;
  toggleFlag: boolean = true;
  ImagingFLag: boolean = false;
  ProviderFlag: boolean = false;
  ImagingFlagImg: boolean = false;
  ProviderFlagImg: boolean = false;
  storeFacilitiesData: any;
  shoMoreRecBtn: boolean = false;
  totalRecord: number;
  showLinked: boolean;
  selectedFacilityType: any;
  siteClickFlag: boolean = false;
  onTextSearchFlag: boolean = false;
  facilityDetails: any = [];
  errUserDetails: any;
  actionActivityDetails: any;
  putFacilityLinkRes: any;
  auditAction: any;
  activeStatus: string = constantStrings.ACTIVESTATUS;
  naStatus: string = constantStrings.NASTATUS.toLowerCase();
  rowDataObj: FacilityRecord; 
  facilityLinkingShowMore: boolean = true;
  totalCount: number = 0;
  imagingType: string = constantStrings.FACILITYIMAGING.toLowerCase();
  providerType: string = constantStrings.FACILITYPROVIDER.toLowerCase();
  selFacilityName: string;
  linkedArray=[];
  unLinkedArray=[];
  toggleClicked: boolean;
  characterLimit=constantStrings.characterLimit;
  constructor(
    private sharedState: SharedStateService,
    private manageFacilityService: ManageFacilityService,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService
  ) {
    this.pagelimit = statusCode.PageLimit;
  }

  ngOnInit(): void {
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
    }
    if (window.innerWidth < 1536 && window.innerWidth > 800) {
      this.resizeWindow = true;
    } else {
      this.resizeWindow = false;
    }
    this.isSurgeonTrue = {
      isDisplaySelectionfacility: false,
      isDisplaySelectionSurgeon: false,
      isDisplaySelectionApplication: false,
      isUploadFailed: false,
      isHelpScreen: false,
    };
    this.getFacilities(this.facilityType);
  }
  /**
  * (description): method called on resizing the window
  * (parameter) : event
  * (memberof) : Component
  */
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth < 1536 && window.innerWidth > 800) {
      this.resizeWindow = true;
    } else {
      this.resizeWindow = false;
    }
  }
  /**
  * (description): method called on click of showmore button
  * (parameter) : event
  * (memberof) : Component
  */
  loadData() {
    this.storeFacilitiesData = [];
    let pageLim;
    if (this.pageOne == 0) {
      pageLim = this.pagelimit
      this.storeFacilitiesData = this.facilitiesRes.slice(0, pageLim);
    } else {
      pageLim = (this.pageOne + 1) * this.pagelimit;
      if (this.facilityResLength >= this.pagelimit) {
        this.storeFacilitiesData = this.facilitiesRes.slice(0, pageLim);
      }
    }
    if (this.storeFacilitiesData.length >= pageLim) {
      this.shoMoreRecBtn = true;
    } else {
      this.shoMoreRecBtn = false;
    }
    this.facilityResLength = this.storeFacilitiesData.length;
    this.dataSource.data = this.storeFacilitiesData;
  }
  //Showmore 1st Table
  showMoreRec() {
    this.pageOne = this.pageOne + 1;
    this.loadData();
  }
  //Showmore 2nd Table
  showMore() {
    if(this.toggleClicked){
      this.page = 1;
    } else {
      this.page = this.page + 1;
    }
    this.getFacilityLinkingDetails(this.selFacilityId);
  }
  //to apply the css based on selection in mat-table
  highlight(row) {
    this.selectedRowIndex = row.facilityId;
  }
  highlightImg(row) {
    this.selectedImgRowIndex = row.facilityId;
  }
  //on Org Table Row Click
  public onOrgRowClick(rowData) {
    if (this.onTextSearchFlag) {
      this.fetchOrgDetails(this.searchTextOrg);
    }
    this.rowDataObj = rowData;
    this.selectedFacilityType = rowData.facilityType;
    this.selFacilityId = rowData.facilityId; 
    this.selFacilityName = rowData.facilityName;
    if (this.siteClickFlag) { 
      this.populateProviderDetails(this.selFacilityId);
      rowData = this.orgDetails[0];
    } else {
      this.populateProviderDetails(this.selFacilityId);
    }
    if (rowData.facilityId) {
      this.selFacilityStatus = rowData.activeStatus;
      this.selFacilityId = rowData.facilityId;
      this.selFacilityName = rowData.facilityName;
    }
    if (rowData && rowData.facilityType && (rowData.facilityType.toLowerCase() === constantStrings.FACILITYIMAGING.toLowerCase())) {
      this.ImagingFLag = true;
      this.ProviderFlag = false;
    } else if (rowData && rowData.facilityType && (rowData.facilityType.toLowerCase() === constantStrings.FACILITYPROVIDER.toLowerCase())) {
      this.ImagingFLag = false;
      this.ProviderFlag = true;
    }
    if (rowData && rowData.activeStatus && (rowData.activeStatus.toLowerCase() === constantStrings.ACTIVESTATUS.toLowerCase())) {
      this.deActivateSite = true;
      this.activateSite = false;
      this.showUnLicensedText = true;
    }
    if (rowData && rowData.activeStatus && (rowData.activeStatus.toLowerCase() === constantStrings.INACTIVESTATUS.toLowerCase())) {
      this.deActivateSite = false;
      this.activateSite = true;
      this.showUnLicensedText = true;
    }
    if (rowData && rowData.activeStatus && (rowData.activeStatus.toLowerCase() === constantStrings.NASTATUS.toLowerCase())) {
      this.deActivateSite = false;
      this.activateSite = false;
      this.showUnLicensedText = false;
    }

  }
  //on Row Click populate Provider Details Data
  public populateProviderDetails(facilityId) {
    this.orgDetails = this.facilitiesRes.filter(
      (obj) => obj.facilityId === facilityId
    );
    this.selectedRowIndex = facilityId;
    this.page = 1;
    this.totalCount = 0;
    this.selectedImgRowIndex = '';
    this.selImgFacilityId = '';
    this.getFacilityLinkingDetails(this.selFacilityId);
  }
  //on Image Table Row Click
  public onImageRowClick(rowData) {
    if(rowData && rowData.facilityId){
      this.selImgFacilityId = rowData.facilityId
    } else {
      this.selImgFacilityId = rowData
    }
    if (this.linkClickFlag && !this.selectedImgRowIndex) {
      this.populateImgCenterDetails(this.selImgFacilityId);
      rowData = this.facilityDetails[0];
    } else {
      this.populateImgCenterDetails(this.selImgFacilityId);
    }
    if (rowData && rowData.facilityType && (rowData.facilityType.toLowerCase() === constantStrings.FACILITYIMAGING.toLowerCase())) {
      this.ImagingFlagImg = true;
      this.ProviderFlagImg = false;
    } else if (rowData && rowData.facilityType && (rowData.facilityType.toLowerCase() === constantStrings.FACILITYPROVIDER.toLowerCase())) {
      this.ProviderFlagImg = true;
      this.ImagingFlagImg = false;
    }
    if (rowData.isLinked === true) {
      this.showLinked = true;
    } else {
      this.showLinked = false;
    }
    if (rowData.isLinked === true && (this.selFacilityId != rowData.facilityId)) {
      this.removeLink = true;
      this.createLink = false;
    } else if (rowData.isLinked === false && (this.selFacilityId != rowData.facilityId)) {
      this.removeLink = false;
      this.createLink = true;
    } else if (this.selFacilityId === rowData.facilityId) {
      this.removeLink = false;
      this.createLink = false;
    }
  }
  //on Row Click populate Image Center Details Data
  public populateImgCenterDetails(facilityId) {
    this.facilityDetails = this.storeFacilityLinkData.filter(
      (obj) => obj.facilityId === facilityId
    );
    this.selectedImgRowIndex = facilityId;
  }

  public onImgSearch() {
    this.page = 1;
    this.totalCount = 0;
    this.getFacilityLinkingDetails(this.selFacilityId);
  }
  //on Orgnanization Search Click
  public onTextSearch() {
    this.page = 1;
    this.totalCount = 0;
    this.onTextSearchFlag = true;
    this.getFacilities(this.facilityType);
  }
  //Fetch Org Details from the  Org Table Data
  public fetchOrgDetails(str) {
    let searchString = str.toLowerCase();
    if (searchString != undefined && searchString != '') {
      let filterOrgDetails = this.facilitiesRes.filter((obj) => {
        if ((obj && obj.facilityName && obj.facilityName.toLowerCase().includes(searchString))
          || (obj && obj.city && obj.city.toLowerCase().includes(searchString))
          || (obj && obj.facilityPhone && obj.facilityPhone.includes(searchString))
          || (obj && obj.facilityContactLead && obj.facilityContactLead.toLowerCase().includes(searchString))
          || (obj && obj.state && obj.state.toLowerCase().includes(searchString))
          || (obj && obj.zipCode && (obj.zipCode + '').includes(searchString))
          || (obj && obj.address && obj.address.toLowerCase().includes(searchString))
          || (obj && obj.facilityEmail && obj.facilityEmail.toLowerCase().includes(searchString))) {
          return true;
        } else return false;
      });
      this.facilityResLength = filterOrgDetails.length;
      this.dataSource.data = filterOrgDetails;
      if (filterOrgDetails.length < 1) {
        this.facilityResLength = 0;
      }
      if (this.facilityResLength >= this.pagelimit) {
        this.shoMoreRecBtn = true;
      } else {
        this.shoMoreRecBtn = false;
      }
    } else {
      this.loadData()
    }
  }
  //on Activate Site
  public onActivateSite() {
    this.siteClickFlag = true;
    this.updateActivationStatus(this.selFacilityId, this.selFacilityStatus);
  }

  //on Activate Site
  public onDeactivateSite() {
    this.siteClickFlag = true;
    this.updateActivationStatus(this.selFacilityId, this.selFacilityStatus);
  }
  //on Create Link Click

  public onCreateLink() {
    let apiParams = {
      sourceFacilityId: (this.selectedFacilityType.toLowerCase() == constantStrings.FACILITYIMAGING.toLowerCase()) ? this.selFacilityId : this.selImgFacilityId,
      targetFacilityId: (this.selectedFacilityType.toLowerCase() == constantStrings.FACILITYIMAGING.toLowerCase()) ? this.selImgFacilityId : this.selFacilityId,
    };
    this.postFacilityLinkingDetails(apiParams);
    this.linkClickFlag = true;
  }

  //on Remove Link Click

  public onRemoveLink() {
    let apiParams = {
      sourceFacilityId: (this.selectedFacilityType == constantStrings.FACILITYIMAGING) ? this.selFacilityId : this.selImgFacilityId,
      targetFacilityId: (this.selectedFacilityType == constantStrings.FACILITYIMAGING) ? this.selImgFacilityId : this.selFacilityId,
    };
    this.putFacilityLinkingDetails(apiParams);
    this.linkClickFlag = true;
  }
  /**
  * (description): method called on click of toogle button
  * (parameter) : event
  * (memberof) : Component
  */
  public toggle(event: MatSlideToggleChange) {
    if (this.facilityListResLength != 0) {
      if (event.checked == false) {
        this.toggleFlag = false;
        this.sortRecAscending();
      }
      if (event.checked == true) {
        this.toggleFlag = true;
        this.sortLinkedUnlinked();
      }
    }
    this.toggleClicked = true;
  }
  /**
  * (description): method called to sort array
  * (parameter) : null
  * (memberof) : Component
  */
  public sortRecAscending() {
    let sortArr = [...this.storeFacilityLinkData];
    let sortedArray = this.sortFacilityData(sortArr);
    this.facilityListResLength = sortedArray.length;
    this.dataSourceTwo.data = sortedArray;
  }
  /**
  * (description): method called to sort array based on facilityname in ascending order
  * (parameter) : null
  * (memberof) : Component
  */
  public sortFacilityData(sortArr){
    return sortArr.sort((a, b) =>
      a.facilityName.toLowerCase() !== b.facilityName.toLowerCase()
        ? a.facilityName.toLowerCase() < b.facilityName.toLowerCase()
          ? -1
          : 1
        : 0
    );
  }
  /**
  * (description): method called to sort array
  * (parameter) : null
  * (memberof) : Component
  */
  public sortLinkedUnlinked() {
    this.linkedArray = [];
    this.unLinkedArray = [];
    for(let key of this.storeFacilityLinkData){
      if(key.isLinked == true){
        this.linkedArray.push(key);
        this.linkedArray = this.linkedArray.sort((a, b) =>
        a.facilityName.toLowerCase() !== b.facilityName.toLowerCase()
          ? a.facilityName.toLowerCase() < b.facilityName.toLowerCase()
            ? -1
            : 1
          : 0
        );
      }else {
        this.unLinkedArray.push(key);
        this.unLinkedArray = this.unLinkedArray.sort((a, b) =>
        a.facilityName.toLowerCase() !== b.facilityName.toLowerCase()
          ? a.facilityName.toLowerCase() < b.facilityName.toLowerCase()
            ? -1
            : 1
          : 0
        );
      }
    }
    this.linkedArray.push(...this.unLinkedArray)
    this.dataSourceTwo.data = this.linkedArray;
  }
  //getFacilities API to fetch the Provider Details
  public getFacilities(facilityType): any {
    this.spinnerService.show();
    this.manageFacilityService.getFacilities(facilityType).subscribe({
      next: result => {
        this.spinnerService.hide();
        this.facilitiesRes = result;        
        if(result.length){
          this.facilityResLength = result.length;
          this.loadData();
          if (this.siteClickFlag) {
            this.onOrgRowClick(this.rowDataObj);
            this.siteClickFlag = false;
          } else {
            if (this.onTextSearchFlag) {
              this.fetchOrgDetails(this.searchTextOrg);
            }
            this.selection = new SelectionModel<manageOrganizations>(true, [
              this.dataSource.data[0],
            ]);
            this.selFacilityId = this.selection.selected[0]['facilityId'];
            this.selFacilityName = this.selection.selected[0]['facilityName'];
            if (this.selection.selected[0] != undefined) {
              this.onOrgRowClick(this.selection.selected[0]);
            }
          }
        } 
      }, error: err => {
        this.errorInGetFacilities = err;
        this.spinnerService.hide();
      }
    });
  }
  // getFacilityLinkingDetails API for fetching the image Center Details
  public getFacilityLinkingDetails(facilityId): any {
    this.spinnerService.show();
    let apiParams: HttpParams = new HttpParams();
    apiParams = apiParams.append('linkedFacilityId', facilityId);
    apiParams = apiParams.append('isUnion', this.isUnion);
    apiParams = apiParams.append('pageno', this.page);
    apiParams = apiParams.append('limit', this.pagelimit);    
    apiParams = apiParams.append('searchstring', this.searchText);
    if(this.toggleFlag){
      apiParams = apiParams.append('isPinned', "true");   
    } else {
      apiParams = apiParams.append('isPinned', "false");   
    }
    this.manageFacilityService.getFacilityLinkingDetails(
      apiParams).subscribe({
        next: result => {
          result.facilityLinkingResponses.forEach((element) => {
            element.facilityContactLead = this.manageFacilityService.decodeValues(element.facilityContactLead);
            element.facilityEmail = this.manageFacilityService.decodeValues(element.facilityEmail);
            element.facilityPhone = this.manageFacilityService.decodeValues(element.facilityPhone);
            element.zipCode = this.manageFacilityService.decodeValues(element.zipCode);
            element.facilityName = this.manageFacilityService.decodeValues(element.facilityName);
            element.facilityImageUrl = this.manageFacilityService.decodeValues(element.facilityImageUrl);
          });
          this.toggleClicked = false;
          this.facilityListRes = result.facilityLinkingResponses;
          if (this.facilityListRes == null) {
            this.dataSourceTwo.data = [];
            this.facilityListResLength = 0;
          }
          this.totalRecord = result.totalRecords;
          if (result.facilityLinkingResponses != null) {
            this.facilityListResLength = result.facilityLinkingResponses.length;
            //Pagination            
            if (this.page == 1) {
              this.storeFacilityLinkData = result.facilityLinkingResponses;
            } else {
              this.storeFacilityLinkData = [
                ...this.storeFacilityLinkData,
                ...result.facilityLinkingResponses,
              ];
            }
            this.totalCount = this.storeFacilityLinkData.length;
            if (this.toggleFlag == true) {
              this.sortLinkedUnlinked();
            } else {
              this.sortRecAscending();
            }

            if (this.linkClickFlag && this.selImgFacilityId) {
              this.onImageRowClick(this.selImgFacilityId);
              this.selectedImgRowIndex = this.selImgFacilityId;
              this.linkClickFlag = false;
            } else if(!this.selectedImgRowIndex){              
              this.selectionTwo = new SelectionModel<facilityDetail>(true, [
                this.dataSourceTwo.data[0],
              ]);
              if (this.selectionTwo.selected[0] != undefined) {
                this.onImageRowClick(this.selectionTwo.selected[0]);
              }

            }
          }
          this.spinnerService.hide();
        }, error: err => {
          this.errorInfacilityList = err;
          this.spinnerService.hide();
        }
      }
    );
  }
  // postFacilityLinkingDetails API for create link
  public postFacilityLinkingDetails(apiParams): any {
    this.spinnerService.show();
    this.manageFacilityService.postFacilityLinkingDetails(
      apiParams).subscribe({
        next: result => {       
          this.postFacilityLinkRes = result;
          this.auditLogActivity(constantStrings.LINK);
          this.createLink = false;
          this.removeLink = true;
          this.updateRecords(true);
          this.spinnerService.hide();
        }, error: err => {
          this.auditLogActivity(constantStrings.LINK);
          this.errInpostFacilityLink = err;
          this.spinnerService.hide();
        }
      }
    );
  }
  // putFacilityLinkingDetails API for create link
  public putFacilityLinkingDetails(apiParams): any {
    this.manageFacilityService.putFacilityLinkingDetails(
      apiParams).subscribe({
        next: result => {      
          this.putFacilityLinkRes = result;
          this.auditLogActivity(constantStrings.DELINK);     
          this.createLink = true;
          this.removeLink = false;
          this.updateRecords(false);
          this.spinnerService.hide();    
        }, error: err => {
          this.auditLogActivity(constantStrings.DELINK);
          this.spinnerService.hide();
          this.errInputFacilityLink = err;
        }
      }
    );
  }
  /**
  * (description): method to update the existing array after click event
  * (parameter) : code
  * (memberof) : Component
  */
  updateRecords(code){
    this.showLinked = code;
    if(this.toggleFlag == true){
      for (let key of this.storeFacilityLinkData) {
        if (key.facilityId == this.selectedImgRowIndex) {
          key.isLinked = code;
        }
      }
      this.sortLinkedUnlinked();
    } else {
      for (let key of this.storeFacilityLinkData) {
        if (key.facilityId == this.selectedImgRowIndex) {
          key.isLinked = code;
        }
      }
      this.sortRecAscending();
    }
  }
  // updateActivationStatus API for create link
  public updateActivationStatus(facilityId, facStatus): any {
    this.spinnerService.show();
    let status: number;
    let audStr;
    if (facStatus.toLowerCase() === constantStrings.INACTIVESTATUS.toLowerCase()) {
      status = statusCode.INACTIVE;
      audStr = constantStrings.ACTIVATESITE;
    } else if (facStatus.toLowerCase() === constantStrings.ACTIVESTATUS.toLowerCase()) {
      status = statusCode.ACTIVE;
      audStr = constantStrings.DEACTIVATESITE;
    }
    let apiParams = {
      activationStatus: status,
      facilityId: facilityId,
    };
    this.manageFacilityService.putActivationStatus(apiParams).subscribe({
      next: result => { 
        this.spinnerService.hide();
        this.postFacilityLinkRes = result;
        this.auditLogActivity(audStr);
        if (status = 1) {
          this.selFacilityStatus = constantStrings.ACTIVESTATUS;
        } else {
          this.selFacilityStatus = constantStrings.INACTIVESTATUS;
        }
        this.getFacilities(this.facilityType);
      }, error: err => {
        this.auditLogActivity(audStr);
        this.spinnerService.hide();
        this.errInputFacilityLink = err;
      }
    });
  }
  /**
  * (description): method to log audit actions
  * (parameter) : action
  * (memberof) : Component
  */
  auditLogActivity(str) {
    let action = {
      "userFacilityId": this.selFacilityId,
      "userName": this.userInfo.userName,
    }
    switch (str) {
      case constantStrings.LINK:
        action["action"] = this.auditAction.UserActions.imagingLinked + this.selFacilityId + '-' + this.selImgFacilityId;
        action["eventOutCome"] = this.postFacilityLinkRes ? 1 : 0;
      break;
      case constantStrings.DELINK:
        action["action"] = this.auditAction.UserActions.imagingDelinked + this.selFacilityId + '-' + this.selImgFacilityId;
        action["eventOutCome"] = this.putFacilityLinkRes ? 1 : 0;
      break;
      case constantStrings.ACTIVATESITE:
        action["action"] = this.auditAction.UserActions.facActivated + this.selFacilityName;
        action["eventOutCome"] = this.postFacilityLinkRes ? 1 : 0;
      break;
      case constantStrings.DEACTIVATESITE:
        action["action"] = this.auditAction.UserActions.facDeactivated + this.selFacilityName;
        action["eventOutCome"] = this.postFacilityLinkRes ? 1 : 0;
        break; 
    }
    this.manageFacilityService.auditLogActivity(action).subscribe({
      next: result => { 
        this.actionActivityDetails = result;
      }, error: err => {
        this.errUserDetails = err;
      }
    });
  }
}

export const environment = {
  duuUploadBucket : 'duu-zip-upload-staging',
  identityPoolId:  'us-east-2:382e25f4-3f07-45e5-b642-7a8e3419229f',
  s3_endpoint: '',
  sts_endpoint: '',
  s3_downUrl: 'https://s3.amazonaws.com',
  appVersion: 'Version 1.0.0',
  baseUrl: 'https://frnb7diz2f.execute-api.us-east-2.amazonaws.com/staging',
  baseUrlClp: 'https://frnb7diz2f.execute-api.us-east-2.amazonaws.com/staging',
  baseUrlClpSnn: 'https://as60i9xbag.execute-api.us-east-2.amazonaws.com/staging',
  baseUnauthUrl: 'https://frnb7diz2f.execute-api.us-east-2.amazonaws.com/staging',
  userPoolAwsLogin:  'cognito-idp.us-east-2.amazonaws.com/us-east-2_ZofmbrRd5',
  production: true,
  lensSenderEmail: 'snnoreply@maildrop.cc',
  destinationBucket: 'cup-dev-lumino',
  region: 'us-east-2',
  apiVersionCognito: '2016-04-18',
  apiVersionSqs: '2012-11-05',
  lambdaFunction: {
    bridgeDuuProcessZipUpload: 'bridge-duu-process-zip-upload-staging',
    lambdaRegion: 'us-east-2',
  },
  ssmParams: {
    IDLETIME : "/duu-dev/image-upload-service_dev/inactivityTime_login",
    DIACOMTAG : "/duu-dev/image-upload-service_dev/validDicomTags",
    RECALLTIME : "/duu-dev/image-upload-service_dev/recallTime",
    AUDITINTERVALTIME : "/duu-dev/image-upload-service_dev/auditInterval",
    APPVERSION: "/duu-staging/image-upload-service_staging/appVersionInfo",
    APPDIACOMTAGSCRIPT : "/duu-qa/image-upload-service_qa/validApplicationDicomTags",
    SQSQUEUEURL: "/duu-qa/DUU_Group_by_patient/group-by-patient-queue-url-qa",
  },

  //ALL ROLES EXPECT SNN
  clp: {
    hostedUIUrl: 'https://bridge-staging-us-east-2.auth.us-east-2.amazoncognito.com/oauth2/',
    hostedUIlogout: 'https://bridge-staging-us-east-2.auth.us-east-2.amazoncognito.com/logout',
    identityPoolId: 'us-east-2:382e25f4-3f07-45e5-b642-7a8e3419229f',
    userPoolId: 'us-east-2_ZofmbrRd5',
    userPoolWebClientId: '7s5mata0ictchilfp4vtvbcknp',
    userPoolAwsLogin: 'cognito-idp.us-east-2.amazonaws.com/us-east-2_ZofmbrRd5',
    cloudfrontCLPURL: 'https://bridge-staging.faiplan-dev.com',
    cloudFrontCLPSignInUrl: 'https://bridge-staging.faiplan-dev.com/signin',
    cloudFrontCLPSignOutUrl: 'https://bridge-staging.faiplan-dev.com/logout',
    cloudFrontCLPExitAppUrl: 'https://bridge-staging.faiplan-dev.com/application/product',
  },
  //SNN ROLE
  snnClp: {
    hostedUIUrl: 'https://bridge-snn-staging-us-east-2.auth.us-east-2.amazoncognito.com/oauth2/',
    hostedUIlogout: 'https://bridge-snn-staging-us-east-2.auth.us-east-2.amazoncognito.com/logout',
    identityPoolId: 'us-east-2:1e9b6b5f-2f85-41bf-8fe3-69e4687dde01',
    userPoolId: 'us-east-2_xHliaypiM',
    userPoolWebClientId: '6suefb10gcmv2urrfjtso3uib',
    userPoolAwsLogin: 'cognito-idp.us-east-2.amazonaws.com/us-east-2_xHliaypiM',
    cloudfrontCLPURL: 'https://bridge-snn-staging.faiplan-dev.com',
    cloudFrontCLPSignInUrl: 'https://bridge-snn-staging.faiplan-dev.com/signin',
    cloudFrontCLPSignOutUrl: 'https://bridge-snn-staging.faiplan-dev.com/logout',
    cloudFrontCLPExitAppUrl: 'https://bridge-snn-staging.faiplan-dev.com/application/product',
  },
  duuCloudFrontSigninUrl: 'https://datauploadutility-staging.aetsamd-dev.com/signin',
};
